import { EyeOutlined } from "@ant-design/icons";
import { Button, Card, Col, Empty, List, Row, Tag, Tooltip } from "antd";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter, isValidArray } from "utils/utilities";

const PastAppointments = () => {
    const { doctorStore } = useMainStore();

    return (
        <Observer>
            {() => {
                const { pastAppointmentsList, isLoadingOfListOfAppointmentByDoctor } = doctorStore;

                if (!isLoadingOfListOfAppointmentByDoctor && !isValidArray(pastAppointmentsList)) {
                    return <Empty />;
                }

                return pastAppointmentsList.map((data) => {
                    return Object.entries(data)
                        .slice()
                        .reverse()
                        .map(([year, appointmentData]) => {
                            return (
                                <Fragment key={year}>
                                    <p className="pl-4 font-weight-bold">{year}</p>
                                    <List
                                        itemLayout="vertical"
                                        size="large"
                                        pagination={false}
                                        dataSource={appointmentData}
                                        renderItem={(item) => {
                                            const {
                                                is_canceled,
                                                patientProfile,
                                                startTime,
                                                appointmentMode,
                                                appointmentType,
                                                patient_id,
                                                appointment_id,
                                                paymentMode,
                                                paymentStatus,
                                                unique_id,
                                            } = item;
                                            return (
                                                <Fragment key={appointment_id}>
                                                    <List.Item>
                                                        <Card className="position-relative">
                                                            {is_canceled && (
                                                                <Tag
                                                                    className="position-absolute cancel-tag rounded-0 text-uppercase"
                                                                    color="#FF6B72"
                                                                    size="large"
                                                                >
                                                                    Canceled
                                                                </Tag>
                                                            )}
                                                            <Row className="d-flex  justify-content-between">
                                                                <Col className="appointment_date px-3">
                                                                    <h1 className="mb-0">
                                                                        {moment.utc(startTime).local().format("DD")}
                                                                    </h1>
                                                                    <h5>
                                                                        {moment.utc(startTime).local().format("MMM")}
                                                                    </h5>
                                                                </Col>

                                                                <Col className="">
                                                                    <h4 className="mb-1">Time</h4>
                                                                    <p className="">
                                                                        {moment
                                                                            .utc(startTime)
                                                                            .local()
                                                                            .format("hh:mm A")}
                                                                    </p>
                                                                </Col>
                                                                <Col span={2}>
                                                                    <h4 className="mb-1">Name</h4>
                                                                    <p className="">{patientProfile.name}</p>
                                                                </Col>
                                                                <Col className="">
                                                                    <h4 className="mb-1">Gender</h4>
                                                                    <p className="">
                                                                        {capitalizeFirstLetter(patientProfile.gender)}
                                                                    </p>
                                                                </Col>
                                                                <Col className="">
                                                                    <h4 className="mb-1">Appointment Mode</h4>
                                                                    <Tag
                                                                        bordered={false}
                                                                        color={
                                                                            appointmentMode !== "offline"
                                                                                ? "blue"
                                                                                : "cyan"
                                                                        }
                                                                    >
                                                                        {appointmentMode === "offline"
                                                                            ? "Physical"
                                                                            : "Virtual"}
                                                                    </Tag>
                                                                </Col>
                                                                <Col className="">
                                                                    <h4 className="mb-1">Appointment Type</h4>
                                                                    <p className="">
                                                                        {capitalizeFirstLetter(appointmentType)}
                                                                    </p>
                                                                </Col>
                                                                <Col className="">
                                                                    <h4 className="mb-1">Payment Status</h4>
                                                                    <Tag
                                                                        color={`${
                                                                            paymentStatus == "paid" ||
                                                                            paymentStatus == "COMPLETED" ||
                                                                            paymentMode == "offline"
                                                                                ? "#6FCE3F"
                                                                                : paymentStatus == "pending"
                                                                                  ? "#FBBF45"
                                                                                  : "#FF6D6D"
                                                                        }`}
                                                                    >
                                                                        {paymentStatus == "paid" ||
                                                                        paymentStatus == "COMPLETED"
                                                                            ? "PAID"
                                                                            : paymentMode == "offline"
                                                                              ? "PAY IN CLINIC"
                                                                              : "PENDING"}
                                                                    </Tag>
                                                                </Col>
                                                                {/* <Col className="">
                                                                <h4 className="mb-1">Booked On</h4>
                                                                <p className="">26 JAN 2024</p>
                                                            </Col> */}
                                                                <Col className="d-flex align-items-center">
                                                                    <Tooltip placement="top" title={"View Details"}>
                                                                        <Button className="" type="primary">
                                                                            <Link
                                                                                to="/doctor/view-appointment"
                                                                                state={{
                                                                                    appointmentId: appointment_id,
                                                                                    patientId: patient_id,
                                                                                    name: patientProfile.name,
                                                                                    type: "past",
                                                                                }}
                                                                            >
                                                                                <EyeOutlined />
                                                                            </Link>
                                                                        </Button>
                                                                    </Tooltip>
                                                                </Col>
                                                            </Row>
                                                            {/* </div> */}
                                                        </Card>
                                                    </List.Item>
                                                </Fragment>
                                            );
                                        }}
                                    />
                                </Fragment>
                            );
                        });
                });
            }}
        </Observer>
    );
};

export default PastAppointments;
