import {
    ArrowLeftOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    EyeOutlined,
    VideoCameraOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Empty, Popconfirm, Row, Table, Tabs, Tag } from "antd";
import BookingConfirmationModal from "components/shared-components/BookingConfirmationModal";
import Loading from "components/shared-components/Loading";
import { DATE_FORMAT_DDDD_DD_MMMM_YYYY_DAY_HH_MM } from "constants/DateConstant";
import { toUpper } from "lodash";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { useEffect } from "react";
import { BiDetail } from "react-icons/bi";
import { FaFilePrescription } from "react-icons/fa";
import { RiSecurePaymentLine } from "react-icons/ri";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { capitalizeFirstLetter, getYears, isValidArray, isValidObject } from "utils/utilities";
import RescheduleAppointmentModal from "./RescheduleAppointmentModal";
import UploadTestReportsModal from "./UploadTestReportsModal";

const columns = [
    {
        title: "Medicine",
        dataIndex: "medicineName",
        key: "medicineName",
        render: (_, { medicineName, medicineType, medicineStrength }) => (
            <p className="text-capitalize">
                {medicineType} {medicineName} - {medicineStrength}
            </p>
        ),
    },
    {
        title: "Medicine Dose",
        dataIndex: "medicineDose",
        key: "medicineDose",
    },
    {
        title: "To be Taken",
        dataIndex: "toBeTaken",
        key: "toBeTaken",
        render: (_, { toBeTaken }) => {
            return toBeTaken == "after_food" ? "After Food" : "Before Food";
        },
    },
    {
        title: "Medicine Time",
        dataIndex: "medicineIntakeTime",
        key: "medicineIntakeTime",
        render: (_, { medicineIntakeTime }) => {
            return medicineIntakeTime.map((data) => {
                return (
                    <Tag
                        color={data == "morning" ? "blue" : data == "noon" ? "yellow" : "orange"}
                        className="text-capitalize"
                    >
                        {" "}
                        {data}
                    </Tag>
                );
            });
        },
    },
    {
        title: "Intake Duration",
        dataIndex: "intakeDuration",
        key: "intakeDuration",
    },

    {
        title: "Note",
        dataIndex: "note",
        key: "note",
    },
];

const ViewPatientAppointment = () => {
    const { patientStore, patientProfileStore } = useMainStore();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    let patientId;
    let appointmentId;

    const appointmentDetails = async () => {
        const {
            getAppointmentDetails,
            setIsLoadingForPatientAppointmentDetails,
            getUploadTestReportsForPrescriptions,
        } = patientStore;
        const { getPatientDetail } = patientProfileStore;

        getAppointmentDetails(patientId, appointmentId)
            .then(async () => {
                await getUploadTestReportsForPrescriptions(patientId, appointmentId);
                await getPatientDetail(patientId);
            })
            .finally(() => {
                setIsLoadingForPatientAppointmentDetails(false);
            });
    };

    useEffect(() => {
        appointmentDetails();
    }, []);

    const handelRescheduleAppointment = async (doctorId, newDate, activeData) => {
        const {
            getDoctorsAvailableSlots,
            setIsLoadingDoctorAvailableSlots,
            setIsAppointmentIdForActive,
            setIsSelectedDateByCalender,
            setIsRescheduleAppointmentModal,
        } = patientStore;

        const appointmentDate = moment(newDate);
        setIsRescheduleAppointmentModal(true);

        const selectedDate = { date: newDate.format("DD-MM-YYYY") };
        setIsSelectedDateByCalender(appointmentDate);
        setIsAppointmentIdForActive(null);
        setIsLoadingDoctorAvailableSlots(true);

        getDoctorsAvailableSlots(doctorId, selectedDate)
            .then(({ data }) => {
                const [{ availableTimings }] = data;
                availableTimings.map(({ time }, _ind) => {
                    if (time == activeData) {
                        setIsAppointmentIdForActive(_ind);
                        return _ind;
                    }
                });
            })
            .finally(() => setIsLoadingDoctorAvailableSlots(false));
    };

    if (isValidObject(state)) {
        const { patient_id, appointment_id } = state;
        patientId = patient_id;
        appointmentId = appointment_id;
    } else {
        patientId = searchParams.get("pId");
        appointmentId = searchParams.get("aId");
    }

    const LabReports = ({ labData, labReportsData }) => {
        const { setIsOpenModalForPriscriptionDocument } = patientStore;

        return (
            <>
                <Observer>
                    {() => {
                        return (
                            <Row className="mt-3">
                                <Col span={8}>
                                    <div>
                                        {" "}
                                        <h4 className="d-block font-weight-bold mb-2">Lab Reports</h4>
                                        {labData.map((data, ind) => {
                                            return (
                                                data !== null && (
                                                    <Tag color="default" key={ind}>
                                                        {toUpper(data)}{" "}
                                                    </Tag>
                                                )
                                            );
                                        })}
                                    </div>
                                </Col>
                                <Col span={12}>
                                    {isValidArray(labReportsData) && (
                                        <>
                                            <h4 className="d-block font-weight-bold mb-2">Uploaded Reports</h4>
                                            {labReportsData.map(({ testName, report }, ind) => {
                                                return (
                                                    <Row key={ind} gutter={[16, 16]}>
                                                        <Col span={12}>{toUpper(testName)}</Col>
                                                        <Col>
                                                            <Button
                                                                href={report}
                                                                target="_blank"
                                                                className="text-center mb-2"
                                                                size="small"
                                                            >
                                                                <EyeOutlined className="font-size-md align-middle" />{" "}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                );
                                            })}
                                        </>
                                    )}
                                </Col>
                                <Col span={4} className="d-flex justify-content-end  ">
                                    <Button onClick={() => setIsOpenModalForPriscriptionDocument(true)} type="primary">
                                        Upload Document
                                    </Button>
                                </Col>
                            </Row>
                        );
                    }}
                </Observer>
            </>
        );
    };
    return (
        <Observer>
            {() => {
                const {
                    patientAppointmentDetails,
                    isLoadingForPatientAppointmentDetails,
                    UploadedTestReportsForPrescriptins,
                    cancelAppointmentByPatient,
                } = patientStore;
                const { patientProfileData } = patientProfileStore;

                const [
                    {
                        is_completed,
                        is_canceled,
                        unique_id,
                        appointmentMode,
                        appointmentType,
                        clinicInfo,
                        doctorDetails,
                        prescription,
                        startTime,
                        time,
                        paymentStatus,
                        doctor_id,
                    },
                ] = patientAppointmentDetails;

                if (isLoadingForPatientAppointmentDetails) {
                    return <Loading />;
                }

                const [patient] = patientProfileData;
                const { name: patientName = "", mobile_no = "", gender = "", dob = "" } = patient;
                const { amount, partner, status, paymentMode } = paymentStatus;
                const [{ uploadedTestReports = [] } = {}] = UploadedTestReportsForPrescriptins;
                const [{ medications = [], testReports: medicationTestReports = [] } = {}] = prescription;
                const [{ name }] = doctorDetails;
                const [{ address, address_url }] = clinicInfo;

                return (
                    <>
                        {searchParams.get("pId") && searchParams.get("aId") && (
                            <BookingConfirmationModal patientId={patientId} appointmentId={appointmentId} />
                        )}
                        <Row justify="space-between" className="mb-2">
                            <Col>
                                <Button
                                    shape="circle"
                                    onClick={() =>
                                        navigate(`/patient/appointments?type=${state?.type ? state?.type : "upcoming"}`)
                                    }
                                >
                                    <ArrowLeftOutlined />
                                </Button>
                                <h4 className="ml-2 d-inline-block">Appointment #: {unique_id}</h4>
                            </Col>
                            {appointmentMode === "online" &&
                                (status === "paid" || status === "COMPLETED") &&
                                !moment.utc(startTime).local().isBefore(moment(), "day") &&
                                !is_completed &&
                                !is_canceled && (
                                    <Col>
                                        <Link
                                            to="/video-consultation"
                                            state={{ userType: 1, appointmentId, patientId, doctorId: doctor_id }}
                                        >
                                            <Button
                                                icon={<VideoCameraOutlined className="font-size-md align-middle" />}
                                                type="primary"
                                                className="d-flex align-items-center"
                                            >
                                                Start Consultation
                                            </Button>
                                        </Link>
                                    </Col>
                                )}
                        </Row>
                        <Row gutter={[16, 16]} className="mt-2">
                            <Col span={16}>
                                <Card>
                                    <Row className="border-bottom pb-2 " align="middle" justify="space-between">
                                        <Col className="d-flex align-items-center">
                                            <BiDetail size={28} />
                                            <span
                                                className={`ml-2 font-weight-bolder font-size-xl  text-capitalize text-gray
                                                }`}
                                            >
                                                Appointment
                                                {`${
                                                    status === "paid" || status === "COMPLETED"
                                                        ? " Details "
                                                        : " Pending"
                                                }`}
                                            </span>
                                        </Col>
                                        <Col className="">
                                            {is_completed && (
                                                <>
                                                    <CheckCircleOutlined
                                                        style={{ color: "green" }}
                                                        className="font-size-xl"
                                                    />
                                                    <h5 className="d-inline-block align-middle ml-1">
                                                        Appointment Completed
                                                    </h5>
                                                </>
                                            )}
                                            {is_canceled && (
                                                <>
                                                    <CloseCircleOutlined
                                                        style={{ color: "red" }}
                                                        className="font-size-xl"
                                                    />
                                                    <h5 className="d-inline-block align-middle ml-1">
                                                        Appointment Canceled
                                                    </h5>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col span={24}>
                                            <Row>
                                                <Col span={24}>
                                                    <Row>
                                                        <Col span={12}>
                                                            <h4 className="d-block font-weight-bold mb-1">
                                                                Doctor Name
                                                            </h4>
                                                            <p>{name}</p>
                                                        </Col>
                                                        <Col span={12}>
                                                            <h4 className="d-block font-weight-bold mb-1">
                                                                Clinic Address
                                                            </h4>
                                                            <p>{address}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={12}>
                                                            <h4 className="d-block font-weight-bold mb-1">
                                                                Patient Name
                                                            </h4>
                                                            <p>{patientName}</p>
                                                        </Col>
                                                        <Col span={12}>
                                                            <h4 className="d-block font-weight-bold mb-1">Age</h4>
                                                            <p>{` ${getYears(moment(dob, "DD-MM-YYYY"))} Years `}</p>
                                                        </Col>
                                                        <Col span={12}>
                                                            <h4 className="d-block font-weight-bold mb-1">Gender</h4>
                                                            <p className="text-capitalize">{gender}</p>
                                                        </Col>
                                                        <Col span={12}>
                                                            <h4 className="d-block font-weight-bold mb-1">
                                                                Mobile No.
                                                            </h4>
                                                            <p>{mobile_no}</p>
                                                        </Col>
                                                        <Col span={12}>
                                                            <h4 className="d-block font-weight-bold mb-1">
                                                                Date & Time
                                                            </h4>
                                                            <p>
                                                                {moment
                                                                    .utc(startTime)
                                                                    .local()
                                                                    .format(DATE_FORMAT_DDDD_DD_MMMM_YYYY_DAY_HH_MM)}
                                                            </p>
                                                        </Col>
                                                        <Col span={12}>
                                                            <h4 className="d-block font-weight-bold mb-1">
                                                                Appointment Type
                                                            </h4>
                                                            <p>{capitalizeFirstLetter(appointmentType)}</p>
                                                        </Col>
                                                        <Col span={24}>
                                                            <Row justify="space-between" align="middle">
                                                                <Col span={12}>
                                                                    {" "}
                                                                    <h4 className="d-block font-weight-bold mb-1">
                                                                        Appointment Mode
                                                                    </h4>
                                                                    <p>
                                                                        {appointmentMode == "online"
                                                                            ? "Virtual"
                                                                            : "Physical"}{" "}
                                                                    </p>
                                                                </Col>
                                                                <Col span={12} className="d-flex justify-content-end">
                                                                    <Popconfirm
                                                                        title="Are you sure to cancel this apointment?"
                                                                        onConfirm={async () => {
                                                                            await cancelAppointmentByPatient(
                                                                                patientId,
                                                                                appointmentId,
                                                                            );
                                                                            await appointmentDetails();
                                                                        }}
                                                                        disabled={
                                                                            is_canceled ||
                                                                            is_completed ||
                                                                            state?.type === "past"
                                                                        }
                                                                        okText="Yes"
                                                                        cancelText="No"
                                                                    >
                                                                        <Button
                                                                            className="ml-2"
                                                                            type="ghost"
                                                                            danger
                                                                            disabled={
                                                                                is_canceled ||
                                                                                is_completed ||
                                                                                state?.type === "past"
                                                                            }
                                                                        >
                                                                            Cancel
                                                                        </Button>
                                                                    </Popconfirm>
                                                                    {/* {(state?.type ? state?.type : "upcoming") ==
                                                                        "upcoming" &&
                                                                        !is_completed && ( */}
                                                                    <Popconfirm
                                                                        title="Are you sure to change this slot time?"
                                                                        onConfirm={() => {
                                                                            const appointmentDate = moment
                                                                                .utc(startTime)
                                                                                .local();
                                                                            handelRescheduleAppointment(
                                                                                doctor_id,
                                                                                appointmentDate,
                                                                                time,
                                                                            );
                                                                            // navigate(
                                                                            //     "/patient/reschedule-appointment",
                                                                            //     {
                                                                            //         state: {
                                                                            //             appointmentId,
                                                                            //             doctorId: doctor_id,
                                                                            //         },
                                                                            //     },
                                                                            // );
                                                                        }}
                                                                        disabled={
                                                                            is_canceled ||
                                                                            is_completed ||
                                                                            state?.type === "past"
                                                                        }
                                                                        okText="Yes"
                                                                        cancelText="No"
                                                                    >
                                                                        <Button
                                                                            className="ml-2"
                                                                            type="primary"
                                                                            ghost
                                                                            disabled={
                                                                                is_canceled ||
                                                                                is_completed ||
                                                                                state?.type === "past"
                                                                            }
                                                                        >
                                                                            Reschedule
                                                                        </Button>
                                                                    </Popconfirm>
                                                                    {/* )} */}
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card className="w-100">
                                    <Row className="pb-3 border-bottom ">
                                        <Col className="d-flex align-items-center">
                                            <RiSecurePaymentLine size={28} />
                                            <span className="ml-2 font-weight-bolder font-size-md ">
                                                Payment Details
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24} className="mt-2">
                                            <Row justify="space-between" align="middle">
                                                <Col span={12}>
                                                    <p className="mb-0">Payment Mode</p>
                                                </Col>
                                                <p className="mb-0 font-weight-bold text-capitalize">{paymentMode}</p>
                                                <Col span={12}></Col>
                                            </Row>
                                            <Row justify="space-between" align="middle">
                                                <Col span={12}>
                                                    <p className="mb-0">Payment Status</p>
                                                </Col>
                                                <Tag
                                                    className={`mb-0 text-uppercase mr-0`}
                                                    color={`${
                                                        status == "paid" ||
                                                        status == "COMPLETED" ||
                                                        paymentMode == "offline"
                                                            ? "#6FCE3F"
                                                            : status == "pending"
                                                              ? "#FBBF45"
                                                              : "#FF6D6D"
                                                    }`}
                                                >
                                                    {status == "paid" || status == "COMPLETED"
                                                        ? "PAID"
                                                        : paymentMode == "offline"
                                                          ? "PAY IN CLINIC"
                                                          : "PENDING"}
                                                </Tag>
                                            </Row>
                                            <Row justify="space-between" align="middle">
                                                <Col span={12}>
                                                    <p className="mb-0">Service Fees</p>
                                                </Col>
                                                <p className="mb-0">${amount}</p>
                                                <Col span={12}></Col>
                                            </Row>
                                            {/* )} */}
                                            <Row justify="space-between" align="middle">
                                                <Col span={12}>
                                                    <p className="mb-0">Any Extra Charges</p>
                                                </Col>
                                                <p className="mb-0">$ 0</p>
                                                <Col span={12}></Col>
                                            </Row>
                                            <Row justify="space-between" align="middle" className="mt-3">
                                                <Col span={12}>
                                                    <h4 className="d-block font-weight-bold mb-1">Total Amount </h4>
                                                </Col>
                                                <h4 className="d-block font-weight-bold mb-1">$ {amount}</h4>
                                                <Col span={12}></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Card>
                                    <Row className="pb-3 border-bottom ">
                                        <Col className="d-flex align-items-center">
                                            <FaFilePrescription color="#FF00002" size={24} />
                                            <span className="ml-2 font-weight-bolder font-size-md ">Prescription</span>
                                        </Col>
                                    </Row>
                                    {isValidArray(prescription) ? (
                                        <Tabs
                                            defaultActiveKey="1"
                                            items={[
                                                {
                                                    key: "1",
                                                    label: "Medication",
                                                    children: (
                                                        <>
                                                            {isValidArray(medications) && medications.length > 0 ? (
                                                                <Table columns={columns} dataSource={medications} />
                                                            ) : (
                                                                <Empty description="No Data Available for Medication" />
                                                            )}
                                                        </>
                                                    ),
                                                },
                                                {
                                                    key: "2",
                                                    label: "Reports",
                                                    children: (
                                                        <>
                                                            {isValidArray(medicationTestReports) &&
                                                            medicationTestReports.length > 0 ? (
                                                                <LabReports
                                                                    labData={medicationTestReports}
                                                                    labReportsData={uploadedTestReports}
                                                                />
                                                            ) : (
                                                                <Empty description="No Data Available for Labs" />
                                                            )}
                                                        </>
                                                    ),
                                                },
                                            ]}
                                        />
                                    ) : (
                                        <Empty
                                            description="Right now, prescription not added by doctor"
                                            className="mt-3"
                                        />
                                    )}
                                </Card>
                            </Col>
                        </Row>
                        <UploadTestReportsModal patientId={patientId} appointmentId={appointmentId} />
                        <RescheduleAppointmentModal
                            appointmentId={appointmentId}
                            doctorId={doctor_id}
                            patientId={patientId}
                            appointmentDetails={appointmentDetails}
                        />
                    </>
                );
            }}
        </Observer>
    );
};
export default ViewPatientAppointment;
