import { Avatar, Button, Card, Col, Empty, Grid, Radio, Row, Spin, Tooltip } from "antd";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Utils from "utils";
import { capitalizeFirstLetter, isValidArray } from "utils/utilities";
import ProfileModal from "./doctorProfileModal";

const { useBreakpoint } = Grid;

const FindDoctors = () => {
    const { globalStore, patientStore } = useMainStore();
    const screens = Utils.getBreakPoint(useBreakpoint());
    const isMobile = screens.length === 0 ? false : !screens.includes("md");
    const navigate = useNavigate();

    const showModal = async (doctorId = "") => {
        try {
            const {
                getDoctorDetailsByDoctorId,
                getDoctorsDetailForPatient,
                setIsLoadingDoctorsProfile,
                setIsModalOpen,
            } = patientStore;
            if (doctorId) {
                setIsLoadingDoctorsProfile(true);
                await getDoctorDetailsByDoctorId(doctorId)
                    .then(({ success, data }) => {
                        if (success) setIsModalOpen(true);
                    })
                    .finally(() => {
                        setIsLoadingDoctorsProfile();
                    });
            }
        } catch (error) {
            console.log("error.message", error.message);
        }
    };

    useEffect(() => {
        const { getAllSpecialities, allSpecialities } = globalStore;
        const {
            getDoctorsBySpecifiedSpeciality,
            setIsLoadingDoctorsList,
            allSpecialityDoctors,
            initialSelectedSpeciality,
            setInitialSelectedSpeciality,
        } = patientStore;
        if (
            (!isValidArray(allSpecialities) && !isValidArray(allSpecialityDoctors)) ||
            initialSelectedSpeciality !== "all"
        ) {
            setInitialSelectedSpeciality("all");
            setIsLoadingDoctorsList(true);
            getAllSpecialities()
                .then(({ success, data }) => {
                    if (success) return data.map(({ _id }) => _id);
                    throw new Error("Something went wrong");
                })
                .then((data) => {
                    return getDoctorsBySpecifiedSpeciality({ specialities: data });
                })
                .then(({ success, data }) => {
                    if (success) setIsLoadingDoctorsList();
                })
                .catch((error) => {
                    console.log("error", error.message);
                })
                .finally(() => {
                    setIsLoadingDoctorsList();
                });
        }
    }, []);

    const getDoctorsBySpecialities = async (value) => {
        const { getDoctorsBySpecifiedSpeciality, setIsLoadingDoctorsList, setInitialSelectedSpeciality } = patientStore;
        const { allSpecialities } = globalStore;
        const targetValue = value.target.value;
        let values = [];

        if (value.target.value === "all") {
            values = allSpecialities.map(({ _id }) => _id);
            setInitialSelectedSpeciality(targetValue);
        } else {
            values = [targetValue];
            setInitialSelectedSpeciality(targetValue);
        }
        setIsLoadingDoctorsList(true);
        await getDoctorsBySpecifiedSpeciality({ specialities: values }).finally(() => setIsLoadingDoctorsList());
    };

    return (
        <Observer>
            {() => {
                const { allSpecialities } = globalStore;
                const {
                    allSpecialityDoctors,
                    isLoadingDoctorsList,
                    isDoctorIdForLoading,
                    isModalOpen,
                    isLoadingDoctorsProfile,
                    setIsLoadingDoctorsProfile,
                } = patientStore;

                return (
                    <Spin spinning={isLoadingDoctorsProfile}>
                        <Row className="mb-2">
                            <h2 className="font-size-lg mb-0">Find Doctor</h2>
                        </Row>
                        <Row>
                            <Col span={24} className="d-flex align-items-center ">
                                <Radio.Group
                                    options={[
                                        {
                                            label: "All",
                                            value: "all",
                                        },
                                        ...allSpecialities.map(({ speciality_name, _id }, index) => ({
                                            value: _id,
                                            label: speciality_name,
                                        })),
                                    ]}
                                    defaultValue={"all"}
                                    onChange={getDoctorsBySpecialities}
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                            </Col>
                        </Row>

                        <Spin spinning={isLoadingDoctorsList}>
                            <Row className="mt-5 justify-content-center justify-content-md-start" gutter={[16, 16]}>
                                {allSpecialityDoctors.map(
                                    ({ doctorProfile, specialities, doctor_id, clinicInfo, fees }, ind) => {
                                        const {
                                            name,
                                            profile_pic = "/img/avatars/thumb-1.jpg",
                                            experience,
                                        } = doctorProfile;

                                        const [{ offline_new_case_fees, address, address_url, city, state }] =
                                            clinicInfo;

                                        return (
                                            <Fragment key={doctor_id}>
                                                <Col span={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
                                                    <Card>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                            }}
                                                            className=""
                                                        >
                                                            <Avatar
                                                                className={isMobile && "d-none"}
                                                                size={120}
                                                                src={profile_pic}
                                                            />
                                                            <div className="ml-4" style={{ width: "70%" }}>
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <Tooltip title="View Profile">
                                                                        <h4
                                                                            className="cursor_pointer mb-0"
                                                                            onClick={() => showModal(doctor_id)}
                                                                        >
                                                                            {capitalizeFirstLetter(name)}
                                                                        </h4>
                                                                    </Tooltip>
                                                                    <Button
                                                                        type="primary"
                                                                        size="small"
                                                                        onClick={() => {
                                                                            setIsLoadingDoctorsProfile(true);
                                                                            navigate("/patient/book-appointment", {
                                                                                state: {
                                                                                    doctorId: doctor_id,
                                                                                    doctorName: name,
                                                                                    doctorAddress: address,
                                                                                    locationUrl: address_url,
                                                                                    appointmentType: "new",
                                                                                },
                                                                            });
                                                                        }}
                                                                    >
                                                                        Book
                                                                    </Button>
                                                                </div>
                                                                <p className="mb-0">{`${city}, ${state}`}</p>
                                                                <p className="font-weight-bold mb-0">
                                                                    <span>{specialities[0].speciality_name}</span>
                                                                    <span className="mx-4"> | </span>
                                                                    <span>Exp: {experience} Years</span>
                                                                </p>

                                                                <div className="">
                                                                    <h4 className="">$ {fees.online_new_case_fees}</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            </Fragment>
                                        );
                                    },
                                )}
                                {!isLoadingDoctorsList && !isValidArray(allSpecialityDoctors) && (
                                    <Col span={24} className=" mt-5">
                                        <Empty description="No Doctors Available" className="h-100" />
                                    </Col>
                                )}
                            </Row>
                            {isModalOpen && <ProfileModal />}
                        </Spin>
                    </Spin>
                );
            }}
        </Observer>
    );
};

export default FindDoctors;
