import axios from "axios";
import { getAllRequiredHeaders } from "../utils/utilities";

const client = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        // "Content-Type": "application/json",
        Accept: "application/json",
    },
});

client.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.log("🚀 ~ error:", error);
        if (error.response.status >= 400) {
            localStorage.clear();
            window.location.href = "/login";
        }
    },
);

export const getRequest = async ({ url }) => {
    try {
        const res = await client.get(`${url}`, {
            headers: { ...getAllRequiredHeaders() },
        });
        const { data, status } = res;
        if (status === 204) {
            return { response: { success: false, message: "Something Went Wrong!", data: [] }, error: true };
        }
        return { response: data, error: false };
    } catch (error) {
        return { response: { isLogout: true }, error: true };
    }
};

export const postRequest = async ({ url, payload = {} }) => {
    try {
        const res = await client.post(`${url}`, payload, {
            headers: { ...getAllRequiredHeaders() },
        });
        const { data, status } = res;
        if (status === 204) {
            return { response: { success: false, message: "Something Went Wrong!", data: [] }, error: true };
        }
        return { response: data, error: false };
    } catch (error) {
        return { response: { isLogout: true }, error: true };
    }
};

export const putRequest = async ({ url, payload = {} }) => {
    try {
        const res = await client.put(`${url}`, payload, {
            headers: { ...getAllRequiredHeaders() },
        });
        const { data, status } = res;
        if (status === 204) {
            return { response: { success: false, message: "Something Went Wrong!", data: [] }, error: true };
        }
        return { response: data, error: false };
    } catch (error) {
        return { response: { isLogout: true }, error: true };
    }
};

export const patchRequest = async ({ url, payload = {} }) => {
    try {
        const res = await client.patch(`${url}`, payload, {
            headers: { ...getAllRequiredHeaders() },
        });
        const { data, status } = res;
        if (status === 204) {
            return { response: { success: false, message: "Something Went Wrong!", data: [] }, error: true };
        }
        return { response: data, error: false };
    } catch (error) {
        return { response: { isLogout: true }, error: true };
    }
};

export const deleteRequest = async ({ url }) => {
    try {
        const res = await client.delete(`${url}`, {
            headers: { ...getAllRequiredHeaders() },
        });
        const { data, status } = res;
        if (status === 204) {
            return { response: { success: false, message: "Something Went Wrong!", data: [] }, error: true };
        }
        return { response: data, error: false };
    } catch (error) {
        return { response: { isLogout: true }, error: true };
    }
};
