import {
    DollarOutlined,
    FieldTimeOutlined,
    LockOutlined,
    QuestionCircleOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { Card, Menu, Row } from "antd";
import { HospitalSVG } from "assets/svg/icon";
import InnerAppLayout from "layouts/inner-app-layout";
import { Fragment, useEffect } from "react";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import useFirstLogin from "utils/hooks/useFirstLogin";
import { getLocalStorageItems } from "utils/utilities";
import EditDoctorProfile from "../profile/EditProfile";
import DiagnosisQuestions from "./AddDiagnosisQuestions";
import ChangePassword from "./ChangePassword";
import ClinicInfo from "./ClinicInfo";
import DoctorAvailability from "./DoctorAvailability";
import DoctorFeesStructure from "./DoctorFeesStructure";

const MenuItem = ({ icon, path, label }) => {
    return (
        <>
            {icon}
            <span>{label}</span>
            <Link to={`/doctor/setting/${path}`} />
        </>
    );
};

const SettingOption = () => {
    const location = useLocation();

    const locationPath = location.pathname.split("/");

    const currentpath = locationPath[locationPath.length - 1];

    return (
        <Menu
            mode="inline"
            selectedKeys={[currentpath]}
            items={[
                {
                    key: "edit-profile",
                    label: <MenuItem label="Edit Profile" icon={<UserOutlined />}  path="edit-profile" />,
                },
                {
                    key: "clinic-info",
                    label: <MenuItem label="Clinic Info" icon={<HospitalSVG />} path="clinic-info" />,
                },
                {
                    key: "fees-structure",
                    label: <MenuItem label="Fees Structure" icon={<DollarOutlined />} path="fees-structure" />,
                },
                {
                    key: "availability",
                    label: <MenuItem label="Availability" icon={<FieldTimeOutlined />} path="availability" />,
                },
                {
                    key: "add-diagnosis-question",
                    label: (
                        <MenuItem
                            label="Diagnosis Questions"
                            icon={<QuestionCircleOutlined />}
                            path="add-diagnosis-question"
                        />
                    ),
                },
                {
                    key: "change-password",
                    label: <MenuItem label="Change Password" icon={<LockOutlined />} path="change-password" />,
                },
            ]}
        />
    );
};

const SettingContent = () => {
    console.log("Inside settings");
    return (
        <Routes>
            <Route path="edit-profile"  element={<EditDoctorProfile />} />
            <Route path="clinic-info" element={<ClinicInfo />} />
            <Route path="fees-structure" element={<DoctorFeesStructure />} />
            <Route path="availability" element={<DoctorAvailability />} />
            <Route path="add-diagnosis-question" element={<DiagnosisQuestions />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="*" element={<Navigate to="edit-profile" replace />} />
        </Routes>
    );
};

const DoctorSettings = () => {
    const isFirstLogin = useFirstLogin();

    useEffect(() => {
        const { user_id, first_login, step } = getLocalStorageItems({
            getAll: true,
            key: ["user_id", "step", "first_login"],
        });
        if (first_login) {
            isFirstLogin(first_login, step - 1);
        }
    }, []);
    return (
        <Fragment>
            <Row>
                <h2>Settings</h2>
            </Row>
            <Card>
                <InnerAppLayout
                    sideContentWidth={250}
                    sideContent={<SettingOption />}
                    mainContent={<SettingContent />}
                />
            </Card>
        </Fragment>
    );
};

export default DoctorSettings;
