import { useMemo } from "react";
import { Navigate } from "react-router-dom";
import { isValidStoredKey } from "utils/utilities";

const ProtectedRoute = ({ children }) => {
    const isAuthenticated = useMemo(() => {
        // check localStorage values
        const isValidKeysWithValues = isValidStoredKey(["user_id", "userType", "access_token", "refresh_token"]);

        return isValidKeysWithValues;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children]);

    return isAuthenticated ? children : <Navigate to={`/login`} replace={true} />;
};

export default ProtectedRoute;
