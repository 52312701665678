import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import Loading from "components/shared-components/Loading";
import { CAPTURE_PAYPAL_ORDER, CREATE_PAYPAL_ORDER } from "constants/ApiConstant";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ERROR, getLocalStorageItems, sendNotification } from "utils/utilities";

const PayPalComponent = () => {
    const { patientStore, patientProfileStore } = useMainStore();
    const location = useLocation();
    const { doctorId, doctorName, doctorAddress, locationUrl } = location.state;
    const { user_id: patientId } = getLocalStorageItems({ get: true, key: "user_id" });
    const navigate = useNavigate();

    const initialOptions = {
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
        "enable-funding": "paylater,venmo",
        "data-sdk-integration-source": "integrationbuilder_sc",
    };

    const [message, setMessage] = useState("");

    const createOrder = async () => {
        try {
            const { isAppointmentFormData, setAppointmentIdState, setIsLoadingForCreatOrderPaypal } = patientStore;
            setIsLoadingForCreatOrderPaypal(true);
            const response = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}/${CREATE_PAYPAL_ORDER}/${patientId}/${doctorId}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(isAppointmentFormData),
                },
            );

            const { success, data, message } = await response.json();

            if (success) {
                const [{ appointmentData, payPalOrderData }] = data;
                const [{ appointment_id }] = appointmentData;
                setIsLoadingForCreatOrderPaypal();

                setAppointmentIdState({ appointment_id: appointment_id });
                return payPalOrderData.id;
            } else {
                setIsLoadingForCreatOrderPaypal();
                sendNotification({ type: ERROR, message: message });
            }
        } catch (error) {
            const { setIsLoadingForCreatOrderPaypal } = patientStore;

            setIsLoadingForCreatOrderPaypal();
            console.error("JJJJJJJJJJJJJJJJJj", error);
            setMessage(`Could not initiate PayPal Checkout...${error}`);
        }
    };

    const onApprove = async (data, actions) => {
        try {
            const {
                setIsPaymentConfirmModal,
                setIsPaymentOptionModal,
                appointmentIdState,
                setIsLoadingForCreatOrderPaypal,
            } = patientStore;
            const { appointment_id } = appointmentIdState;
            setIsLoadingForCreatOrderPaypal(true);
            const response = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}/${CAPTURE_PAYPAL_ORDER}/${data.orderID}/${appointment_id}/${doctorId}/${patientId}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );

            const orderData = await response.json();
            // Three cases to handle:
            //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
            //   (2) Other non-recoverable errors -> Show a failure message
            //   (3) Successful transaction -> Show confirmation or thank you message

            const errorDetail = orderData?.details?.[0];

            if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                return actions.restart();
            } else if (errorDetail) {
                // (2) Other non-recoverable errors -> Show a failure message
                throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
            } else {
                // (3) Successful transaction -> Show confirmation or thank you message
                // Or go to another URL:  actions.redirect('thank_you.html');
                const { purchase_units } = orderData;
                const [{ reference_id: appointment_id }] = purchase_units;
                const transaction = orderData.purchase_units[0].payments.captures[0];
                setIsPaymentOptionModal();
                setIsLoadingForCreatOrderPaypal(false);
                // setIsPaymentConfirmModal(true);
                navigate(`/patient/view-appointment?pId=${patientId}&aId=${appointment_id}&type=upcoming`);
                // setMessage(
                //     `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`,
                // );
                console.log("Capture result", orderData, JSON.stringify(orderData, null, 2));
            }
        } catch (error) {
            console.error(error);
            const { setIsLoadingForCreatOrderPaypal } = patientStore;
            setIsLoadingForCreatOrderPaypal(false);
            setMessage(`Sorry, your transaction could not be processed...${error}`);
        }
    };

    function Message({ content }) {
        return <p>{content}</p>;
    }
    return (
        <Observer>
            {() => {
                const { isLoadingForCreatOrderPaypal } = patientStore;
                return (
                    <>
                        <PayPalScriptProvider options={initialOptions}>
                            <PayPalButtons
                                style={{
                                    shape: "rect",
                                    // color: 'blue' ,
                                    layout: "vertical", //default value. Can be changed to horizontal
                                }}
                                createOrder={createOrder}
                                onApprove={(data, actions) => onApprove(data, actions)}
                            />
                        </PayPalScriptProvider>
                        {isLoadingForCreatOrderPaypal && <Loading />}
                        <Message content={message} />
                    </>
                );
            }}
        </Observer>
    );
};

export default PayPalComponent;
