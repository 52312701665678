import { PATIENTS_DASHBOARD_STATISTICS, PATIENT_DASHBOARD_APPOINT_LIST, PATIENT_RESENT_PRESCRIPTIONS_LIST } from "constants/ApiConstant"
import { makeAutoObservable, runInAction } from "mobx"
import moment from "moment"
import { getRequest, postRequest } from "utils/api"
import { getValidDataFromResponse, isValidArray } from "utils/utilities"

class PatientDashboardStore {

    patientStatistics = [{}]
    dashboardAppointmentList = []
    resentPrescriptionList = []

    isLoadingDashboardData = true

    constructor() {
        makeAutoObservable(this)
    }


    getPatientStatistics = async (patientId) => {
        return new Promise(async (resolve, reject) => {
            try {

                const { error, response } = await getRequest({ url: `${PATIENTS_DASHBOARD_STATISTICS}/${patientId}` })
                const { error: responseError, data } = getValidDataFromResponse({ response })

                if (!responseError && isValidArray(data)) {
                    this.setPatientStatistics(data)
                    return resolve({ success: true, data: data })
                }
                this.setPatientStatistics()
                return resolve({ success: false, data: [] })

            } catch (error) {
                this.setPatientStatistics()
                return reject({ success: false, data: [], message: error.message })
            }
        })
    }

    getDashboardAppointList = async ({ patientId, limit = 10 }) => {
        return new Promise(async (resolve, reject) => {
            try {


                const { error, response } = await getRequest({ url: `${PATIENT_DASHBOARD_APPOINT_LIST}/${patientId}?limit=${limit}`})
                const { error: responseError, data } = getValidDataFromResponse({ response })

                if (!responseError && isValidArray(data)) {
                    this.setDashboardAppointmentList(data)
                    return resolve({ success: true, data: data })
                }
                this.setDashboardAppointmentList()
                return resolve({ success: false, data: [] })

            } catch (error) {
                this.setDashboardAppointmentList()
                return reject({ success: false, data: [], message: error.message })
            }
        })
    }

    getResentPrescriptionList = async ({ patientId, limit = 10 }) => {
        return new Promise(async (resolve, reject) => {
            try {

                const { error, response } = await getRequest({ url: `${PATIENT_RESENT_PRESCRIPTIONS_LIST}/${patientId}?limit=${limit}` })
                const { error: responseError, data } = getValidDataFromResponse({ response })

                if (!responseError && isValidArray(data)) {
                    this.serResentPrescriptionList(data)
                    return resolve({ success: true, data: data })
                }
                this.serResentPrescriptionList()
                return resolve({ success: false, data: [] })

            } catch (error) {
                this.serResentPrescriptionList()
                return reject({ success: false, data: [], message: error.message })
            }
        })
    }

    setPatientStatistics = (data = [{}]) => {
        runInAction(() => {
            this.patientStatistics = data
        })
    }

    setIsLoadingDashboardData = (value = false) => {
        runInAction(() => {
            this.isLoadingDashboardData = value
        })
    }

    setDashboardAppointmentList = (data = []) => {
        runInAction(() => {
            this.dashboardAppointmentList = data
        })
    }

    serResentPrescriptionList = (data = []) => {
        runInAction(() => {
            this.resentPrescriptionList = data
        })
    }


}

export default PatientDashboardStore