import { EyeOutlined } from "@ant-design/icons";
import { Button, Col, Row, Spin, Table, Tag } from "antd";
import { DATE_FORMAT_DDDD_DD_MMMM_YYYY_DAY_HH_MM, DATE_FORMAT_MMM_DD_YYYY_DAY_HH_MM } from "constants/DateConstant";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { getLocalStorageItems, isValidArray } from "utils/utilities";

const DoctorTransactions = () => {
    const { doctorStore } = useMainStore();

    const { user_id: doctorId } = getLocalStorageItems({ get: true, key: "user_id" });

    useEffect(() => {
        const { getDoctorTransectionsDetails, setIsLoadingForDoctorTransectionsDetails } = doctorStore;
        setIsLoadingForDoctorTransectionsDetails(true);
        getDoctorTransectionsDetails(doctorId).then(({ success, data }) => {
            if (success) setIsLoadingForDoctorTransectionsDetails(false);
        }).finally(() => {
            setIsLoadingForDoctorTransectionsDetails(false);
        });
    }, []);

    const columns = [
        {
            title: "Payment Date",
            dataIndex: "paidOn",
            key: "paidOn",
            render: (_, { paidOn }, ind) => moment(paidOn).format(DATE_FORMAT_DDDD_DD_MMMM_YYYY_DAY_HH_MM),
        },
        {
            title: "Appointment Fees",
            dataIndex: "appointment_fees",
            key: "appointment_fees",
            render: (_, { appointment_fees }, ind) => <p className="text-capitalize mb-0">$ {appointment_fees}</p>,
        },
        {
            title: "Platform Fees (in %)",
            dataIndex: "platform_fees",
            key: "platform_fees",
            render: (_, { platform_fees }, ind) => <p className="text-capitalize mb-0">{platform_fees}</p>,
        },
        {
            title: "Receivable Amount",
            dataIndex: "amount",
            key: "amount",
            render: (_, { amount }, ind) => <p className="text-capitalize mb-0">$ {amount}</p>,
        },
        {
            title: "Payment Status",
            dataIndex: "status",
            key: "status",
            render: (_, { status }, ind) => (
                <Tag
                    color={`${
                        status == "paid" || status == "COMPLETED"
                            ? "#6FCE3F"
                            : status == "pending"
                              ? "#FBBF45"
                              : "#FF6D6D"
                    }`}
                    key={ind}
                >
                    {status == "paid" || status == "COMPLETED" ? "PAID" : "PENDING"}
                </Tag>
            ),
        },
        {
            title: <Row justify="center">View Appointment</Row>,
            dataIndex: "appointment_id",
            key: "appointment_id",
            render: (_, { appointment_id, patient_id }, ind) => (
                <Row justify="center">
                    <Button className="tabel_icon" type="primary" ghost>
                        <Link
                            to="/doctor/view-appointment"
                            state={{
                                appointmentId: appointment_id,
                                patientId: patient_id,
                            }}
                        >
                            <EyeOutlined />
                        </Link>
                    </Button>
                </Row>
            ),
        },
    ];
    return (
        <Observer>
            {() => {
                const { isLoadingForDoctorTransectionsDetails, doctorTransectionsDetails } = doctorStore;
                return (
                    <Spin spinning={isLoadingForDoctorTransectionsDetails && !isValidArray(doctorTransectionsDetails)}>
                        <Row>
                            <h2> Transactions History</h2>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Table
                                    columns={columns}
                                    dataSource={doctorTransectionsDetails.map((item, ind) => ({
                                        ...item,
                                        key: ind,
                                    }))}
                                />
                            </Col>
                        </Row>
                    </Spin>
                );
            }}
        </Observer>
    );
};

export default DoctorTransactions;
