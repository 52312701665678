import { Card, Col, Row } from "antd";
import { useMainStore } from "mobxStore/mainStore";
import { Link } from "react-router-dom";
import SignInForm from "../components/SignInForm";

const SignIn = () => {
    const { signInStore } = useMainStore();

    const backgroundStyle = {
        backgroundColor: "#8faaf9",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",
    };

    return (
        <div style={backgroundStyle}>
            <div className="h-100">
                <div className="container d-flex flex-column justify-content-center h-100">
                    <Row justify="center">
                        <Col xs={20} sm={20} md={20} lg={7}>
                            <Card>
                                <div className="my-4">
                                    <Row justify="center">
                                        <Col xs={24} sm={24} md={20} lg={20}>
                                            <SignInForm />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="text-center">
                                    <p>
                                        Already have an account? <Link to="/login">Login</Link>
                                    </p>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default SignIn;
