import { Card, Col, Row } from "antd";
import { useMainStore } from "mobxStore/mainStore";
import { Link } from "react-router-dom";
import LoginForm from "../components/LoginForm";

const Login = () => {
    const mainStore = useMainStore();
    const { loginStore } = mainStore;
    console.log("🚀 ~ file: index.js:11 ~ Login ~ loginStore:", loginStore);

    const backgroundStyle = {
        backgroundColor: "#8faaf9",
        backgroundSize: "cover",
        height: "100vh",
    };
    return (
        <div style={backgroundStyle}>
            <div className="h-100">
                <div className="container d-flex flex-column justify-content-center h-100">
                    <Row justify="center">
                        <Col xs={20} sm={20} md={20} lg={7}>
                            <Card>
                                <div className="my-4">
                                    <Row justify="center">
                                        <Col xs={24} sm={24} md={20} lg={20}>
                                            <LoginForm />
                                            {/* <p className="text-right ">
                                                <Link to="/forgot-password">
                                                    Forgot Password ?
                                                </Link>
                                            </p> */}
                                        </Col>
                                    </Row>
                                </div>
                                <div className="text-center">
                                    <p>
                                        Don't have an account yet? <Link to="/sign-in">Sign Up</Link>
                                    </p>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default Login;
