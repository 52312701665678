const FirebaseConfig = {
    apiKey: "AIzaSyAOWoRQGoegkmwnCca7B2GG5GUG_ZP1lcM",
    authDomain: "emilus.firebaseapp.com",
    databaseURL: "https://emilus.firebaseio.com",
    projectId: "emilus",
    storageBucket: "emilus.appspot.com",
    messagingSenderId: "807555350717",
    appId: "1:807555350717:web:145ba7c21af47203a2f7d4",
    measurementId: "G-8KE7HJB252",
};

export default FirebaseConfig;
