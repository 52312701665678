import {
    ADD_DIAGNOSIS_QUESTION_BY_DOCTOR,
    ADD_OR_UPDATE_DOCTOR_AVAILABILITY_INFO,
    ADD_OR_UPDATE_DOCTOR_CLINIC_INFO,
    ADD_OR_UPDATE_FEES_STRUCTURE,
    GET_DIAGNOSIS_QUESTIONS_BY_DOCTOR,
    GET_DOCTOR_AVAILABILITY_INFO,
    GET_DOCTOR_CLINIC_INFO,
    GET_DOCTOR_DETAILS,
    GET_DOCTOR_FEES_STRUCTURE,
    UPDATE_DOCTOR_PROFILE,
} from "constants/ApiConstant";
import { makeAutoObservable, runInAction } from "mobx";
import { getRequest, patchRequest, postRequest } from "utils/api";

import { getValidDataFromResponse, isValidArray } from "utils/utilities";
import moment from "moment";
class DoctorProfileStore {
    doctorProfileData = [{}];
    doctorClinicInfo = [{}];
    doctorFeesStructure = [{}];

    initialAvailabilityData = {
        slotDuration: "",
        bufferTime: "",
        availabilityData: [
            { day: "Monday", isAvailable: false, availability: [], startTime: "", endTime: "" },
            { day: "Tuesday", isAvailable: false, availability: [], startTime: "", endTime: "" },
            { day: "Wednesday", isAvailable: false, availability: [], startTime: "", endTime: "" },
            { day: "Thursday", isAvailable: false, availability: [], startTime: "", endTime: "" },
            { day: "Friday", isAvailable: false, availability: [], startTime: "", endTime: "" },
            { day: "Saturday", isAvailable: false, availability: [], startTime: "", endTime: "" },
            { day: "Sunday", isAvailable: false, availability: [], startTime: "", endTime: "" },
        ],
    };
    doctorAvailabilityData = {
        slotDuration: "",
        bufferTime: "",
        availabilityData: [
            { day: "Monday", isAvailable: false, availability: [], startTime: "", endTime: "" },
            { day: "Tuesday", isAvailable: false, availability: [], startTime: "", endTime: "" },
            { day: "Wednesday", isAvailable: false, availability: [], startTime: "", endTime: "" },
            { day: "Thursday", isAvailable: false, availability: [], startTime: "", endTime: "" },
            { day: "Friday", isAvailable: false, availability: [], startTime: "", endTime: "" },
            { day: "Saturday", isAvailable: false, availability: [], startTime: "", endTime: "" },
            { day: "Sunday", isAvailable: false, availability: [], startTime: "", endTime: "" },
        ],
    };
    isDiagnosisQuestions = [{}];

    isLoadingProfileData = true;
    isUpdatingProfileData = false;
    isLoadingClinicInfo = true;
    isUpdatingClinicInfo = false;
    isUpdatingDoctorAvailabilityData = false;
    isLoadingAvailabilityData = true;
    isLoadingDiagnosisQuestions = true;
    isUpdatingFeesStructure = false;
    isLoadingDoctorFees = false;
    isUpdatingQuestions = false;

    constructor({ globalStore }) {
        makeAutoObservable(this);
        this.globalStore = globalStore;
    }

    getDoctorProfileData = async (doctorId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await getRequest({ url: `${GET_DOCTOR_DETAILS}/${doctorId}?` });

                const { data, error } = getValidDataFromResponse({ response });

                if (!error) {
                    this.setDoctorProfileData(data);
                    return resolve({ success: true, data });
                }
                this.setDoctorProfileData();
                return resolve({ success: false, data: [{}] });
            } catch (error) {
                console.log("getDoctorProfileData", error.message);
                this.setDoctorProfileData();
                return resolve({ success: false, data: [{}] });
            }
        });
    };

    updateDoctorProfile = async ({ body, doctorId }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await patchRequest({
                    url: `${UPDATE_DOCTOR_PROFILE}/${doctorId}?step=0&first_login=true`,
                    payload: body,
                });

                const { data, error } = getValidDataFromResponse({ response, isRequired: true, isWarning: true });

                if (!error) {
                    this.getDoctorProfileData(doctorId);
                    this.globalStore.getUserDetailsById(doctorId);
                    return resolve({ success: true, data });
                }

                return reject({ success: false, data: [] });
            } catch (error) {
                console.log("updateDoctorProfile", error.message);
                return reject({ success: false, data: [] });
            }
        });
    };

    getDoctorClinicInfo = async ({ doctorId }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await getRequest({ url: `${GET_DOCTOR_CLINIC_INFO}/${doctorId}` });

                const { data, error } = getValidDataFromResponse({ response });

                if (!error && isValidArray(data)) {
                    this.setDoctorClinicInfo(data);
                    return resolve({ success: true, data });
                }
                return resolve({ success: false, data: [] });
            } catch (error) {
                this.setDoctorClinicInfo();
                return resolve({ success: false, data: [] });
            }
        });
    };

    addOrUpdateDoctorClinicInfo = async ({ payload, doctorId }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await patchRequest({
                    url: `${ADD_OR_UPDATE_DOCTOR_CLINIC_INFO}/${doctorId}?step=1&first_login=true`,
                    payload,
                });

                const { data, error } = getValidDataFromResponse({ response, isRequired: true, isWarning: true });

                if (!error && isValidArray(data)) {
                    return resolve({ success: true, data });
                }

                return resolve({ success: false, data: [] });
            } catch (error) {
                return resolve({ success: false, data: [] });
            }
        });
    };

    // GET doctor availability information
    getDoctorAvailabilityInfo = async (doctorId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await getRequest({
                    url: `${GET_DOCTOR_AVAILABILITY_INFO}/${doctorId}`,
                });

                const { data, error } = getValidDataFromResponse({ response });

                if (!error && isValidArray(data)) {
                    const [{ timings, slotDuration, bufferTime }] = data;
                    this.setDoctorAvailabilityData({
                        slotDuration: slotDuration,
                        bufferTime: bufferTime,
                        availabilityData: timings.map(({ day, isAvailable, availability, startTime, endTime }) => {
                            const newStartTime = isAvailable ? moment.utc(startTime).local().format("hh:mm A") : "";
                           const newEndTime = isAvailable ? moment.utc(endTime).local().format("hh:mm A") : "";
                            return {
                                day,
                                isAvailable,
                                availability,
                                startTime: startTime ? moment(newStartTime, "hh:mm A") : "",
                                endTime: endTime ? moment(newEndTime, "hh:mm A") : "",
                            };
                        }),
                    });
                    return resolve({ success: true, data });
                }
                this.setDoctorAvailabilityData(this.initialAvailabilityData);
                return resolve({ success: false, data: [] });
            } catch (error) {
                this.setDoctorAvailabilityData();
                return resolve({ success: false, data: [] });
            }
        });
    };

    // add or update doctor availability information
    addOrUpdateDoctorAvailabilityInfo = (availabilityData, doctorId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const newBody = {
                    ...availabilityData,
                };

                const { response } = await patchRequest({
                    url: `${ADD_OR_UPDATE_DOCTOR_AVAILABILITY_INFO}/${doctorId}?step=3&first_login=true`,
                    payload: newBody,
                });

                const { data, error } = getValidDataFromResponse({ response, isRequired: true, isWarning: true });

                if (!error && isValidArray(data)) {
                    return resolve({ success: true, data });
                }

                return resolve({ success: false, data: [] });
            } catch (error) {
                return resolve({ success: false, data: [] });
            }
        });
    };

    // add diagnosis questions for patient by specific doctors
    addDiagnosisQuestionsBySpecialist = async ({ doctorId, questionsData }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await postRequest({
                    url: `${ADD_DIAGNOSIS_QUESTION_BY_DOCTOR}/${doctorId}?step=4&first_login=false`,
                    payload: questionsData,
                });
                const { data, error: err } = getValidDataFromResponse({ response, isRequired: true, isWarning: true });
                if (!err && isValidArray(data)) {
                    return resolve({ success: true, data });
                }
                return resolve({ success: true, data: [{}] });
            } catch (error) {
                return resolve({ success: false, data: [{}] });
            }
        });
    };

    // get diagnosis questions by doctor
    getDiagnosisQuestionsByDoctor = async ({ doctorId }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await getRequest({ url: `${GET_DIAGNOSIS_QUESTIONS_BY_DOCTOR}/${doctorId}` });
                const { data, error } = getValidDataFromResponse({ response });
                if (!error && isValidArray(data)) {
                    const [{ questions }] = data;
                    this.setIsDiagnosisQuestions(data);
                    return resolve({ success: true, data });
                }
                return resolve({ success: true, data: [{}] });
            } catch (error) {
                console.log("error", error);
                return resolve({ success: false, data: [{}] });
            }
        });
    };

    getDoctorFeesDetailsById = async (doctorId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await getRequest({ url: `${GET_DOCTOR_FEES_STRUCTURE}/${doctorId}` });
                const { data, error } = getValidDataFromResponse({ response });

                if (!error && isValidArray(data)) {
                    this.setDoctorFeesStructure(data);
                    return resolve({ success: true, data: data });
                }

                return resolve({ success: false, data: [] });
            } catch (error) {
                console.log("error", error);
                return resolve({ success: false, data: [] });
            }
        });
    };

    addOrUpdateFeesStructure = async ({ doctorId, feesInfo }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await patchRequest({
                    url: `${ADD_OR_UPDATE_FEES_STRUCTURE}/${doctorId}?step=2&first_login=true`,
                    payload: feesInfo,
                });
                const { data, error } = getValidDataFromResponse({ response, isRequired: true, isWarning: true });

                if (!error && isValidArray(data)) {
                    return resolve({ success: true, data: [] });
                }

                return resolve({ success: false, data: [] });
            } catch (error) {
                console.log("error", error);
                return resolve({ success: false, data: [] });
            }
        });
    };

    setDoctorProfileData = (data = [{}]) => {
        runInAction(() => {
            this.doctorProfileData = data;
        });
    };

    setIsLoadingProfileData = (value = false) => {
        runInAction(() => {
            this.isLoadingProfileData = value;
        });
    };

    setIsUpdatingProfileData = (value = false) => {
        runInAction(() => {
            this.isUpdatingProfileData = value;
        });
    };

    setDoctorClinicInfo = (data = [{}]) => {
        runInAction(() => {
            this.doctorClinicInfo = data;
        });
    };

    setIsUpdatingClinicInfo = (value = false) => {
        runInAction(() => {
            this.isUpdatingClinicInfo = value;
        });
    };

    setIsLoadingClinicInfo = (value = false) => {
        runInAction(() => (this.isLoadingClinicInfo = value));
    };

    setDoctorAvailabilityData = (data = this.initialAvailabilityData) => {
        runInAction(() => {
            this.doctorAvailabilityData = data;
        });
    };

    setIsUpdatingDoctorAvailabilityData = (value = false) => {
        runInAction(() => {
            this.isUpdatingDoctorAvailabilityData = value;
        });
    };

    setIsLoadingAvailabilityData = (value = false) => {
        runInAction(() => {
            this.isLoadingAvailabilityData = value;
        });
    };
    setIsDiagnosisQuestions = (data = [{}]) => {
        runInAction(() => {
            this.isDiagnosisQuestions = data;
        });
    };
    setIsLoadingDiagnosisQuestions = (value = false) => {
        runInAction(() => {
            this.isLoadingDiagnosisQuestions = value;
        });
    };

    setDoctorFeesStructure = (data = [{}]) => {
        runInAction(() => {
            this.doctorFeesStructure = data;
        });
    };

    setIsLoadingDoctorFees = (value = false) => {
        runInAction(() => {
            this.isLoadingDoctorFees = value;
        });
    };

    setIsUpdatingFeesStructure = (value = false) => {
        runInAction(() => {
            this.isUpdatingFeesStructure = value;
        });
    };

    setIsUpdatingQuestions = (value = false) => {
        runInAction(() => {
            this.isUpdatingQuestions = value;
        });
    };
}

export default DoctorProfileStore;
