import { APPOINTMENT_DETAILS, UPDATE_APPOINTMENT_COMPLETION_STATUS, USER_DETAILS_BY_ID } from "constants/ApiConstant";
import { makeAutoObservable, runInAction } from "mobx";
import { getRequest, postRequest } from "utils/api";
import { getValidDataFromResponse, isValidArray } from "utils/utilities";

class VideoConferenceStore {
    roomName = "";
    appointmentDetails = [];
    userDetails = [{}];

    isLoadingAppointment = true;
    isUpdatingCompletionStatus = false;

    constructor() {
        makeAutoObservable(this);
    }

    getAppointmentDetailsById = async (appointmentId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await getRequest({ url: `${APPOINTMENT_DETAILS}/${appointmentId}` });

                const { error: responseError, data } = getValidDataFromResponse({ response });

                if (!responseError && isValidArray(data)) {
                    this.setAppointmentDetails(data);
                    return resolve({ success: true, data: [] });
                }

                this.setAppointmentDetails();
                return resolve({ success: false, data: [] });
            } catch (error) {
                this.setAppointmentDetails();
                return reject({ success: false, message: error.message, data: [] });
            }
        });
    };

    getUserDetailsById = async (userId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await getRequest({ url: `${USER_DETAILS_BY_ID}/${userId}` });

                const { error: responseError, data } = getValidDataFromResponse({ response });

                if (!responseError && isValidArray(data)) {
                    this.setUserDetails(data);
                    return resolve({ success: true, data: [] });
                }

                this.setUserDetails();
                return resolve({ success: false, data: [] });
            } catch (error) {
                this.setUserDetails();
                return reject({ success: false, message: error.message, data: [] });
            }
        });
    };

    updateAppointmentCompletionStatus = async (payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { completionStatus, appointmentId, doctorId, patientId, updatedBy } = payload;

                const newBody = {
                    completionStatus,
                    appointmentId,
                    doctorId,
                    patientId,
                    updatedBy,
                };

                const { response } = await postRequest({
                    url: `${UPDATE_APPOINTMENT_COMPLETION_STATUS}`,
                    payload: newBody,
                });

                const { error: responseError, data } = getValidDataFromResponse({ response });

                if (!responseError && isValidArray(data)) {
                    return resolve({ success: true, data: [] });
                }

                return resolve({ success: false, data: [] });
            } catch (error) {
                return reject({ success: false, message: error.message, data: [] });
            }
        });
    };

    setRoomName = (roomName = "") => {
        runInAction(() => {
            this.roomName = roomName;
        });
    };

    setIsLoadingAppointment = (value = false) => {
        runInAction(() => {
            this.isLoadingAppointment = value;
        });
    };

    setAppointmentDetails = (data = []) => {
        runInAction(() => {
            this.appointmentDetails = data;
        });
    };

    setUserDetails = (data = [{}]) => {
        runInAction(() => {
            this.userDetails = data;
        });
    };

    setIsUpdatingCompletionStatus = (value = false) => {
        runInAction(() => {
            this.isUpdatingCompletionStatus = value;
        });
    };
}

export default VideoConferenceStore;
