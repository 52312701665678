import { APPOINTMENT_FOR_DOCTOR } from "constants/ApiConstant"
import { makeAutoObservable, runInAction } from "mobx"
import { getRequest } from "utils/api"
import { getValidDataFromResponse, isValidArray } from "utils/utilities"

class DoctorViewAppointmentStore {

    isLoadingAppointment = false

    appointmentData = [{}]

    constructor() {
        makeAutoObservable(this)
    }

    getSingleAppointmentDataForDoctor = ({ doctorId, appointmentId }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await getRequest({ url: `${APPOINTMENT_FOR_DOCTOR}/${doctorId}/${appointmentId}` });

                const { data, error } = getValidDataFromResponse({ response });

                if (!error && isValidArray(data)) {

                    this.setAppointmentData(data)
                    return resolve({ success: true, data: data });
                }

                this.setAppointmentData()
                return resolve({ success: false, data: [] });
            } catch (error) {

                return reject({ success: false, data: [] });
            }
        });
    }

    setAppointmentData = (data = [{}]) => {
        runInAction(() => {
            this.appointmentData = data
        })
    }

    setIsLoadingAppointment = (value = false) => {
        runInAction(() => {
            this.isLoadingAppointment = value
        })
    }

}

export default DoctorViewAppointmentStore