import { Button, Col, Form, Input, Row } from "antd";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import { useNavigate } from "react-router-dom";
import { getLocalStorageItems } from "utils/utilities";

const ChangePassword = () => {
    const [form] = Form.useForm();

    const navigate = useNavigate();
    const { globalStore } = useMainStore();

    const onFinish = (value) => {
        const { user_id } = getLocalStorageItems({ get: true, key: "user_id" });

        const { updateUserPassword } = globalStore;

        updateUserPassword({ userId: user_id, body: value }).then(({ success, data }) => {
            if (success) {
                form.resetFields();
                navigate("/");
            }
        });
    };

    return (
        <Observer>
            {() => {
                const { isUpdatingUserPasswords } = globalStore;
                return (
                    <>
                        <h2 className="mb-4">Change Password</h2>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={8}>
                                <Form form={form} name="changePasswordForm" layout="vertical" onFinish={onFinish}>
                                    <Form.Item
                                        label="Current Password"
                                        name="currentPassword"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter your currrent password!",
                                            },
                                        ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                    <Form.Item
                                        label="New Password"
                                        name="newPassword"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter your new password!",
                                            },
                                        ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                    <Form.Item
                                        label="Confirm Password"
                                        name="confirmPassword"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please confirm your password!",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (!value || getFieldValue("newPassword") === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject("Password not matched!");
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                    <p className="m-0 font-size-sm">
                                        by changing password, you will be logged out and you need to login again using
                                        new password.
                                    </p>
                                    <Button type="primary" htmlType="submit" loading={isUpdatingUserPasswords}>
                                        Change password
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </>
                );
            }}
        </Observer>
    );
};

export default ChangePassword;
