import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Radio, Row } from "antd";
import { DoctorSVG, PatientSVG } from "assets/svg/icon";
import { isArray } from "lodash";
import { toJS } from "mobx";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const SignInForm = () => {
    const { signInStore } = useMainStore();

    const navigate = useNavigate();

    // const [UserType, setValueUserType] = useState("doctor");
    const [form] = Form.useForm();

    const [activeUserType, setActiveUserType] = useState(0);

    useEffect(() => {
        const { getUserTypes } = signInStore;
        getUserTypes();
    }, []);

    const rules = {
        user_type: [
            {
                required: true,
                message: "Please select your type",
            },
        ],
        email: [
            {
                required: true,
                message: "Please input your email address",
            },
            {
                type: "email",
                message: "Please enter a validate email!",
            },
        ],
        password: [
            {
                required: true,
                message: "Please input your password",
            },
        ],
    };

    const onFinish = async (values) => {
        try {
            const { userTypes, createNewUser } = signInStore;
            const { _id } = userTypes.find(({ type }) => type == activeUserType);

            const new_body = {
                ...values,
                type: _id,
            };
            const { success, data } = await createNewUser(new_body);

            if (success) {
                navigate("/login");
            }
        } catch (error) {}
    };

    return (
        <>
            <Observer>
                {() => {
                    const { userTypes, isCreatingNewUser } = signInStore;
                    return (
                        <>
                            <div className="text-center">
                                <h3>Sign In</h3>
                                <p> Continue as</p>
                            </div>
                            <Form layout="vertical" form={form} name="register-form" onFinish={onFinish}>
                                <Row gutter={[24, 0]} justify="center">
                                    <Col sm={10} md={12} xl={8}>
                                        <Card
                                            className={`${
                                                activeUserType === 0 ? "active_card" : ""
                                            } cursor_pointer p-0 `}
                                            // title="Doctor"
                                            // bodyStyle={{width: '100px'}}
                                            onClick={() => setActiveUserType(0)}
                                        >
                                            <p className="mb-1 font-weight-bold">Doctor</p>
                                            <DoctorSVG />
                                        </Card>
                                    </Col>
                                    <Col sm={10} md={12} xl={8}>
                                        <Card
                                            className={`${
                                                activeUserType === 1 ? "active_card" : ""
                                            } cursor_pointer p-0`}
                                            // title="Patient"
                                            onClick={() => setActiveUserType(1)}
                                        >
                                            <p className="mb-1 font-weight-bold">Patient</p>
                                            <PatientSVG />
                                        </Card>
                                    </Col>
                                </Row>
                                <Form.Item name="email" label="Email" rules={rules.email} hasFeedback>
                                    <Input prefix={<MailOutlined className="text-primary" />} />
                                </Form.Item>
                                <Form.Item name="password" label="Password" rules={rules.password} hasFeedback>
                                    <Input.Password prefix={<LockOutlined className="text-primary" />} />
                                </Form.Item>
                                <Form.Item>
                                    <Button loading={isCreatingNewUser} type="primary" htmlType="submit" block>
                                        Sign Up
                                    </Button>
                                </Form.Item>
                            </Form>
                        </>
                    );
                }}
            </Observer>
        </>
    );
};

export default SignInForm;
