import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "vDoctor";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const REDIRECT_URL_KEY = "redirect";
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboards/default`;
// export const AUTHENTICATED_ENTRY = `/doctor/overview`;
export const DOCTOR_PREFIX_PATH = "/doctor";
export const PATIENT_PREFIX_PATH = "/patient";
export const UNAUTHENTICATED_ENTRY = "/login";
export const PATIENT_LOGIN = "/patient/overview";
export const DOCTOR_LOGIN = "/doctor/overview";
export const LOGIN_TYPE = "patient";

export const THEME_CONFIG = {
    navCollapsed: false,
    sideNavTheme: SIDE_NAV_LIGHT,
    locale: "en",
    navType: NAV_TYPE_SIDE,
    topNavColor: "#3e82f7",
    headerNavColor: "",
    mobileNav: false,
    currentTheme: "light",
    direction: DIR_LTR,
    blankLayout: false,
};
