import { useNavigate } from "react-router-dom";
import { setLocalStorageItems } from "utils/utilities";

const useFirstLogin = () => {
    const navigate = useNavigate();
    const isFirstLogin = (firstLogin, step) => {
        if (!firstLogin) return;

        switch (step + 1) {
            case 0: {
                setLocalStorageItems({
                    set: true,
                    item: {
                        key: "step",
                        value: 0,
                    },
                });
                return navigate("/doctor/setting/edit-profile");
            }
            case 1: {
                setLocalStorageItems({
                    set: true,
                    item: {
                        key: "step",
                        value: 1,
                    },
                });
                return navigate("/doctor/setting/clinic-info");
            }

            case 2: {
                setLocalStorageItems({
                    set: true,
                    item: {
                        key: "step",
                        value: 2,
                    },
                });
                return navigate("/doctor/setting/fees-structure");
            }

            case 3: {
                setLocalStorageItems({
                    set: true,
                    item: {
                        key: "step",
                        value: 3,
                    },
                });
                return navigate("/doctor/setting/availability");
            }

            case 4: {
                setLocalStorageItems({
                    set: true,
                    item: {
                        key: "step",
                        value: 4,
                    },
                });
                return navigate("/doctor/setting/add-diagnosis-question");
            }
            default:
                return;
            // navigate("/doctor/dashboard");
        }
    };

    return isFirstLogin;
};

export default useFirstLogin;
