import { Button, Col, Modal, Row } from "antd";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const BookingConfirmationModal = ({ patientId, appointmentId }) => {
    const { patientStore } = useMainStore();
    const navigate = useNavigate();

    useEffect(() => {
        const {
            getAppointmentConfirmationStatus,
            setIsLoadingAppointmentConfirmationStatus,
            setBookingAppointmentConfirmationModal,
        } = patientStore;
        setBookingAppointmentConfirmationModal(true);
        setIsLoadingAppointmentConfirmationStatus(true);
        getAppointmentConfirmationStatus(appointmentId)
            .then(({ success }) => {
                if (success) {
                    setIsLoadingAppointmentConfirmationStatus();
                }
            })
            .finally(() => setIsLoadingAppointmentConfirmationStatus())
            .catch((err) => {});
    }, []);

    return (
        <Observer>
            {() => {
                const {
                    isLoadingAppointmentConfirmationStatus,
                    appointmentConfirmationStatus,
                    setBookingAppointmentConfirmationModal,
                    bookingAppointmentConfirmationModal,
                } = patientStore;

                if (isLoadingAppointmentConfirmationStatus) {
                    return;
                }
                const [{ appointmentDate, paymentDetails, unique_id, time, _id }] = appointmentConfirmationStatus;
                return (
                    <div className="">
                        <Modal
                            open={bookingAppointmentConfirmationModal}
                            spinning={isLoadingAppointmentConfirmationStatus}
                            maskClosable={false}
                            footer={false}
                            closable={false}
                            onCancel={() => setBookingAppointmentConfirmationModal(false)}
                            title={
                                paymentDetails.status == "paid" ||
                                paymentDetails.status == "COMPLETED" ||
                                paymentDetails.paymentMode == "offline" ? (
                                    <div className="d-flex align-items-center ">
                                        <span className=" d-block font-size-xl font-weight-bold ml-2 mb-0 text-success ">
                                            Appointment Booking Confirmed
                                        </span>
                                    </div>
                                ) : (
                                    <div className="d-flex align-items-center">
                                        <h2 className="d-inline-block mb-0 text-warning ">Appointment Pending</h2>
                                    </div>
                                )
                            }
                        >
                            <Row>
                                <Col span={24} className="">
                                    {paymentDetails.status == "paid" ||
                                    paymentDetails.status == "COMPLETED" ||
                                    paymentDetails.paymentMode == "offline" ? (
                                        <div className="d-flex align-items-center ">
                                            <h4 className="font-weight-normal ">
                                                We are thrilled to confirm your appointment booking with vDoctor! Thank
                                                you for choosing us.
                                            </h4>
                                        </div>
                                    ) : (
                                        <div className="d-flex align-items-center">
                                            <h2 className="font-weight-normal">
                                                    Appointment pending due to unsettled payment
                                                    If you already paid then after some time it will be updated
                                            </h2>
                                        </div>
                                    )}
                                </Col>
                                <Col className="mt-2">
                                    <div>
                                        <h5 className="d-inline-block ">Appointment UID :</h5>{" "}
                                        <p className="d-inline-block">{unique_id}</p>
                                    </div>
                                    <div>
                                        <h5 className="d-inline-block ">Payment Status:</h5>{" "}
                                        <p className="d-inline-block">
                                            {paymentDetails.status == "paid" || paymentDetails.status == "COMPLETED"
                                                ? "Paid"
                                                : "Pending"}
                                        </p>
                                    </div>
                                    <div>
                                        <h5 className="d-inline-block ">Amount: </h5>
                                        <p className="d-inline-block"> ${paymentDetails.amount}</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row justify="end">
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        navigate("/patient/view-appointment", {
                                            state: {
                                                appointment_id: appointmentId,
                                                patient_id: patientId,
                                                type: "upcoming",
                                            },
                                        });
                                        setBookingAppointmentConfirmationModal();
                                    }}
                                >
                                    Ok
                                </Button>
                            </Row>
                        </Modal>
                    </div>
                );
            }}
        </Observer>
    );
};

export default BookingConfirmationModal;
