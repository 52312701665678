import {
    GET_APPOINTMENT_LIST_BY_DOCTOR,
    GET_DOCTOR_AVAILABLE_BALANCE,
    GET_DOCTOR_PAYMENT_HISTORY,
    GET_DOCTOR_PAYOUT_LIST,
    UPDATE_APPOINTMENT_STATUS_BY_DOCTOR,
} from "constants/ApiConstant";
import { makeAutoObservable, runInAction } from "mobx";
import moment from "moment";
import { getRequest, patchRequest } from "utils/api";
import { getValidDataFromResponse, isValidArray } from "utils/utilities";

class DoctorStore {
    listOfAppointmentByDoctor = [];
    defaultSelectedDate = moment();
    pastAppointmentsList = [];
    upcomingAppointmentsList = [];
    doctorTransectionsDetails = [];
    doctorPayoutList = [];
    doctorAvailableBalance = [];
    // loading state
    isLoadingForDoctorPayoutList = true;
    isLoadingOfListOfAppointmentByDoctor = true;
    isLoadingPastAppoint = false;
    isLoadingUpcomingAppoint = false;
    isLoadingForDoctorTransectionsDetails = true;

    constructor() {
        makeAutoObservable(this);
    }

    getPatientAppointmenListByDoctor = async (doctorId, type, date = "") => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await getRequest({
                    url: `${GET_APPOINTMENT_LIST_BY_DOCTOR}/${doctorId}?type=${type}&date=${date ? date : ""}`,
                });
                const { data, error: err } = getValidDataFromResponse({ response });

                if (!err && isValidArray(data)) {
                    if (type === "past") {
                        this.setPastAppointmentsList(data);
                        return resolve({ success: true, data: data });
                    }

                    if (type === "upcoming") {
                        this.setUpcomingAppointmentsList(data);
                        return resolve({ success: true, data: data });
                    }
                }
                // this.setPastAppointmentsList();
                // this.setUpcomingAppointmentsList();
                this.setUpcomingAppointmentsList();
                return resolve({ success: false, data: [] });
            } catch (error) {
                console.log("🚀 ~ PatientStore ~ returnnewPromise ~ error:", error);
                this.setUpcomingAppointmentsList();
                return resolve({ success: false, data: [] });
            }
        });
    };
    getDoctorTransectionsDetails = async (doctorId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { error, response } = await getRequest({ url: `${GET_DOCTOR_PAYMENT_HISTORY}/${doctorId}` });
                const { data, error: responseError } = getValidDataFromResponse({ response });

                if (!error && !responseError && isValidArray(data)) {
                    this.setDoctorTransectionsDetails(data);
                    return resolve({ success: true, data: data });
                }
                this.setDoctorTransectionsDetails();
                return resolve({ success: false, data: [] });
            } catch (error) {
                this.setDoctorTransectionsDetails();
                return reject({ success: false, data: [] });
            }
        });
    };
    updateAppointmentStatusByDoctor = (appointmentId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { error, response } = await patchRequest({
                    url: `${UPDATE_APPOINTMENT_STATUS_BY_DOCTOR}/${appointmentId}`,
                });
                const { data, error: responseError } = getValidDataFromResponse({ response });

                if (!error && !responseError && isValidArray(data)) {
                    return resolve({ success: true, data: data });
                }
                return resolve({ success: false, data: [] });
            } catch (error) {
                return reject({ success: false, data: [] });
            }
        });
    };

    // docor payouts

    getPayoutListForDoctor = async (doctorId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { error, response } = await getRequest({ url: `${GET_DOCTOR_PAYOUT_LIST}/${doctorId}` });
                const { data, error: responseError } = getValidDataFromResponse({ response });

                if (!error && !responseError && isValidArray(data)) {
                    this.setDoctorPayoutList(data);
                    return resolve({ success: true, data: data });
                }
                this.setDoctorPayoutList([]);
                return resolve({ success: false, data: [] });
            } catch (error) {
                this.setDoctorPayoutList([]);
                return reject({ success: false, data: [] });
            }
        });
    };

    getDoctorAvailableBalance = async (doctorId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { error, response } = await getRequest({ url: `${GET_DOCTOR_AVAILABLE_BALANCE}/${doctorId}` });
                const { data, error: responseError } = getValidDataFromResponse({ response });

                if (!error && !responseError && isValidArray(data)) {
                    this.setDoctorAvailableBalance(data);
                    return resolve({ success: true, data: data });
                }
                this.setDoctorAvailableBalance([]);
                return resolve({ success: false, data: [] });
            } catch (error) {
                this.setDoctorAvailableBalance([]);
                return reject({ success: false, data: [] });
            }
        });
    };

    setListOfAppointmentByDoctor = (data = []) => {
        runInAction(() => {
            this.listOfAppointmentByDoctor = data;
        });
    };
    setDoctorTransectionsDetails = (data = []) => {
        runInAction(() => {
            this.doctorTransectionsDetails = data;
        });
    };
    setDoctorPayoutList = (data = []) => {
        runInAction(() => {
            this.doctorPayoutList = data;
        });
    };
    setDoctorAvailableBalance = (data = []) => {
        runInAction(() => {
            this.doctorAvailableBalance = data;
        });
    };
    // set loading state
    setIsLoadingOfListOfAppointmentByDoctor = (isLoading = false) => {
        runInAction(() => {
            this.isLoadingOfListOfAppointmentByDoctor = isLoading;
        });
    };
    setIsLoadingForDoctorTransectionsDetails = (isLoading = false) => {
        runInAction(() => {
            this.isLoadingForDoctorTransectionsDetails = isLoading;
        });
    };

    setDefaultSelectedDate = (date = moment()) => {
        runInAction(() => {
            this.defaultSelectedDate = date;
        });
    };

    setPastAppointmentsList = (data = []) => {
        runInAction(() => {
            this.pastAppointmentsList = data;
        });
    };

    setUpcomingAppointmentsList = (data = []) => {
        runInAction(() => {
            this.upcomingAppointmentsList = data;
        });
    };

    setIsLoadingPastAppoint = (value = false) => {
        runInAction(() => {
            this.isLoadingPastAppoint = value;
        });
    };

    setIsLoadingUpcomingAppoint = (value = false) => {
        runInAction(() => {
            this.isLoadingUpcomingAppoint = value;
        });
    };
    setIsLoadingForDoctorPayoutList = (loading = false) => {
        runInAction(() => {
            this.isLoadingForDoctorPayoutList = loading;
        });
    };
}
export default DoctorStore;
