import { EditOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, DatePicker, Form, Input, Radio, Row, Space, Spin, Upload } from "antd";
import Loading from "components/shared-components/Loading";
import { DATE_FORMAT_DD_MM_YYYY } from "constants/DateConstant";
import { ROW_GUTTER } from "constants/ThemeConstant";
import { toJS } from "mobx";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { useEffect, useRef } from "react";
import { getLocalStorageItems, isValidArray, isValidObject } from "utils/utilities";

const PatientProfile = () => {
    const mainStore = useMainStore();
    const { patientProfileStore, globalStore } = mainStore;
    const [form] = Form.useForm();

    const uploadRef = useRef(null)

    useEffect(() => {
        const { getGenderKeyValue } = globalStore;
        const { getPatientDetail, setIsLoadingPatientProfileData } = patientProfileStore;

        setIsLoadingPatientProfileData(true)
        const { user_id } = getLocalStorageItems({ get: true, key: "user_id" });

        getGenderKeyValue();

        getPatientDetail(user_id).finally(() => setIsLoadingPatientProfileData());
    }, []);

    const formRules = {
        name: {
            required: true,
            message: "This field is required!",
        },
        gender: {
            required: true,
            message: "This field is required!",
        },
        dob: {
            required: true,
            message: "This field is required!",
        },
        mobile_no: {
            required: true,
            message: "This field is required!",
        },
    };

    const onFinish = async (values) => {
        const formValue = {
            name: values.name,
            dob: values.dob.format("DD-MM-YYYY"),
            mobile_no: values.mobile_no,
            gender: values.gender,
        };

        try {
            const { user_id } = getLocalStorageItems({ get: true, key: "user_id" });
            const { editPatientProfile } = patientProfileStore;
            editPatientProfile(user_id, formValue);

        } catch (error) {
            console.log("error", error);
        }
    };

    const fileUploadSettings = {
        accept: 'image/*',
        beforeUpload: file => {
            return false
        },
        onChange: info => {
            try {

                const { updateProfileImage, getUserDetailsById } = globalStore
                const { getPatientDetail } = patientProfileStore
                const { user_id: patientId } = getLocalStorageItems({ get: true, key: "user_id" });


                if (info.fileList.length > 0) {
                    const [uploadedFile] = info.fileList;
                    const fileName = uploadedFile.name;

                    if (isValidObject(uploadedFile)) {

                        const formData = new FormData()
                        formData.append('file', uploadedFile.originFileObj)

                        updateProfileImage({ userId: patientId, formData }).then(({ success, data }) => {
                            if (success) {
                                getUserDetailsById(patientId)
                                getPatientDetail(patientId)
                            }
                        })
                    }

                }
            } catch (error) {
            }
        },
    };

    return (
        <Observer>
            {() => {
                const { genderKeyValue, isUpdatingProfileImage } = globalStore;
                const { isProfileUpdated, patientProfileData, isLoadingPatientProfileData } = patientProfileStore;

                if (isLoadingPatientProfileData && !isValidArray(patientProfileData)) {
                    return <Loading />;
                }
                const [{ name, mobile_no, dob, gender, profile_pic } = {}] = patientProfileData;

                return (
                    <>
                        <Row>
                            <h2>My Profile</h2>
                        </Row>

                        <Row
                            align="middle"
                            gutter={[16, 16]}
                            className="justify-content-center justify-content-lg-start flex-column flex-lg-row "
                        >
                            <Col>
                                <div className="profile_img">
                                    <Avatar
                                        size={90}
                                        src={profile_pic ? profile_pic : "/img/avatars/thumb-6.jpg"}
                                        icon={<EditOutlined />}
                                    />
                                    <EditOutlined
                                        className="cursor_pointer"
                                        onClick={() => uploadRef.current.click()}
                                    />
                                    <Space size={[16]}>
                                        <Upload
                                            className="mr-2"
                                            disabled={isUpdatingProfileImage}
                                            {...fileUploadSettings}
                                            fileList={[]}
                                            maxCount={1}
                                        >
                                            <Button
                                                ref={uploadRef}
                                                className="p-0 d-none"
                                                loading={isUpdatingProfileImage}
                                                type="primary"
                                            >
                                                {isUpdatingProfileImage ? " Updating... " : ""}
                                            </Button>
                                        </Upload>
                                    </Space>
                                </div>
                            </Col>
                            <Col></Col>
                        </Row>
                        <div className="mt-4">
                            <Form
                                name="basicInformation"
                                layout="vertical"
                                initialValues={{
                                    name: name,
                                    gender: gender,
                                    mobile_no: mobile_no,
                                    dob: dob ? moment(dob, "DD-MM-YYYY") : moment().subtract(25, "y"),
                                }}
                                onFinish={onFinish}
                            >
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={16}>
                                        <Row gutter={ROW_GUTTER}>
                                            <Col xs={24} sm={24} md={12}>
                                                <Form.Item label="Name" name="name" rules={[formRules.name]}>
                                                    <Input placeholder="John" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={12}>
                                                <Form.Item label="Gender" name="gender" rules={[formRules.gender]}>
                                                    <Radio.Group>
                                                        {genderKeyValue?.map(({ label, value }) => (
                                                            <Radio key={value} value={value}>
                                                                {label}
                                                            </Radio>
                                                        ))}
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={12}>
                                                <Form.Item label="Date of Birth" name="dob" rules={[formRules.dob]}>
                                                    <DatePicker
                                                        allowClear={false}
                                                        className="w-100"
                                                        format={DATE_FORMAT_DD_MM_YYYY}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={12}>
                                                <Form.Item
                                                    label="Mobile No."
                                                    name="mobile_no"
                                                    rules={[formRules.mobile_no]}
                                                >
                                                    <Input className="w-100" placeholder="Mobile" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Button type="primary" htmlType="submit">
                                            Save Change
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </>
                );
            }}
        </Observer>
    );
};

export default PatientProfile;
