import { EditOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select, Space, Upload } from "antd";
import Flex from "components/shared-components/Flex";
import Loading from "components/shared-components/Loading";
import { DATE_FORMAT_DD_MM_YYYY } from "constants/DateConstant";
import { ROW_GUTTER } from "constants/ThemeConstant";
import { Observer } from "mobx-react-lite";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { Fragment, useEffect, useRef } from "react";
import useFirstLogin from "utils/hooks/useFirstLogin";
import { getLocalStorageItems, isValidObject } from "utils/utilities";

const EditDoctorProfile = () => {
    const { globalStore, doctorProfileStore } = useMainStore();
    const isFirstLogin = useFirstLogin();

    const uploadRef = useRef();

    useEffect(() => {
        const { getAllSpecialities, getAllDegrees, getExperienceKeyValue, getGenderKeyValue } = globalStore;
        const { setIsLoadingProfileData, getDoctorProfileData } = doctorProfileStore;

        const { user_id } = getLocalStorageItems({ get: true, key: "user_id" });

        getGenderKeyValue();
        getExperienceKeyValue();

        getAllSpecialities()
            .then(() => getAllDegrees())
            .then(() => getDoctorProfileData(user_id))
            .finally(() => setIsLoadingProfileData(false));
    }, []);

    const formRules = {
        name: {
            required: true,
            message: "This field is required!",
        },
        gender: {
            required: true,
            message: "This field is required!",
        },
        dob: {
            required: true,
            message: "This field is required!",
        },
        experience: {
            required: true,
            message: "This field is required!",
        },
        mobile_no: {
            required: true,
            message: "This field is required!",
        },
        speciality: {
            required: true,
            message: "This field is required!",
        },
        degree: {
            required: true,
            message: "This field is required!",
        },
        about: {
            required: true,
            message: "This field is required!",
        },
    };
  
    
    const onFinish = async (values) => {
        const { updateDoctorProfile, setIsUpdatingProfileData, getDoctorProfileData } = doctorProfileStore;
        const { user_id: doctorId, first_login } = getLocalStorageItems({
            getAll: true,
            key: ["user_id", "step", "first_login"],
        });

        try {
            setIsUpdatingProfileData(true);
            const { name, degree, dob, speciality, ...rest } = values;
            const newBody = {
                ...rest,
                name: name.includes("Dr.") || name.includes("dr.") ? name : "Dr." + " " + name,
                speciality: speciality.map((spe) => spe).join(","),
                degree: degree.map((deg) => deg).join(","),
                dob: moment(dob).format(DATE_FORMAT_DD_MM_YYYY),
            };

            await updateDoctorProfile({ body: newBody, doctorId })
                .then(() => {
                    getDoctorProfileData(doctorId);
                    setIsUpdatingProfileData();
                })
                .finally(() => {
                    setIsUpdatingProfileData();
                    isFirstLogin(first_login, 0);
                });
        } catch (error) {
            console.log("onFinish", error.message);
        }
    };

    const fileUploadSettings = {
        accept: "image/*",
        beforeUpload: (file) => {
            return false;
        },
        onChange: (info) => {
            try {
                const { updateProfileImage, getUserDetailsById } = globalStore;
                const { getDoctorProfileData } = doctorProfileStore;
                const { user_id: doctorId } = getLocalStorageItems({ get: true, key: "user_id" });

                if (info.fileList.length > 0) {
                    const [uploadedFile] = info.fileList;
                    const fileName = uploadedFile.name;

                    if (isValidObject(uploadedFile)) {
                        const formData = new FormData();
                        formData.append("file", uploadedFile.originFileObj);

                        updateProfileImage({ userId: doctorId, formData }).then(({ success, data }) => {
                            if (success) {
                                getUserDetailsById(doctorId);
                                getDoctorProfileData(doctorId);
                            }
                        });
                    }
                }
            } catch (error) {}
        },
    };

    return (
        <Observer>
            {() => {
                const { allSpecialities, allDegrees, experienceKeyValue, genderKeyValue, isUpdatingProfileImage } =
                    globalStore;
                const { isLoadingProfileData, doctorProfileData, isUpdatingProfileData } = doctorProfileStore;
                const [{ name, dob, gender, about, mobile_no, experience, specialities, degrees, profile_pic }] =
                    doctorProfileData;

                if (isLoadingProfileData) {
                    return <Loading />;
                }

                return (
                    <Fragment>
                        <>
                            <Flex alignItems="center" mobileFlex={false} className="text-center text-md-left">
                                <Space>
                                    {/* <Avatar size={90} src={profile_pic ? profile_pic : "/img/avatars/thumb-6.jpg"} icon={<UserOutlined />} /> */}
                                    {/* <div className="ml-3 mt-md-0 mt-3">
                                        <Upload disabled={isUpdatingProfileImage} {...fileUploadSettings} fileList={[]} maxCount={1}>
                                            <Button loading={isUpdatingProfileImage} type="primary">{isUpdatingProfileImage ? " Updating... " : "Change Profile"}</Button>
                                        </Upload>
                                    </div> */}
                                    <div className="profile_img">
                                        <Avatar
                                            size={90}
                                            src={profile_pic ? profile_pic : "/img/avatars/thumb-6.jpg"}
                                            icon={<EditOutlined />}
                                        />
                                        <EditOutlined
                                            className="cursor_pointer"
                                            onClick={() => uploadRef.current.click()}
                                        />
                                        <Space size={[16]}>
                                            <Upload
                                                className="mr-2"
                                                disabled={isUpdatingProfileImage}
                                                {...fileUploadSettings}
                                                fileList={[]}
                                                maxCount={1}
                                            >
                                                <Button
                                                    ref={uploadRef}
                                                    className="p-0 d-none"
                                                    loading={isUpdatingProfileImage}
                                                    type="primary"
                                                >
                                                    {isUpdatingProfileImage ? " Updating... " : ""}
                                                </Button>
                                            </Upload>
                                        </Space>
                                    </div>
                                </Space>
                            </Flex>
                            <div className="mt-4">
                                <Form
                                    name="basicInformation"
                                    layout="vertical"
                                    initialValues={{
                                        name: name,
                                        gender,
                                        about,
                                        mobile_no,
                                        experience,
                                        dob: dob ? moment(dob, "DD-MM-YYYY") : moment().subtract(25, "y"),
                                        speciality: specialities?.map(({ speciality_id }) => speciality_id),
                                        degree: degrees?.map(({ degree_id }) => degree_id),
                                    }}
                                    onFinish={onFinish}
                                >
                                    <Row>
                                        <Col span={24}>
                                            <Row gutter={ROW_GUTTER}>
                                                <Col xxl={8} xl={12} xs={24}>
                                                    <Form.Item label="Name" name="name" rules={[formRules.name]}>
                                                        <Input placeholder="John" />
                                                    </Form.Item>
                                                </Col>
                                                <Col xxl={8} xl={12} xs={24}>
                                                    <Form.Item label="Date of Birth" name="dob" rules={[formRules.dob]}>
                                                        <DatePicker
                                                            allowClear={false}
                                                            className="w-100"
                                                            format={DATE_FORMAT_DD_MM_YYYY}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xxl={8} xl={12} xs={24}>
                                                    <Form.Item label="Gender" name="gender" rules={[formRules.gender]}>
                                                        <Radio.Group>
                                                            {genderKeyValue?.map(({ label, value }) => (
                                                                <Radio key={value} value={value}>
                                                                    {label}
                                                                </Radio>
                                                            ))}
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Col>
                                                <Col xxl={8} xl={12} xs={24}>
                                                    <Form.Item
                                                        label="Experience (Years)"
                                                        name="experience"
                                                        rules={[formRules.experience]}
                                                    >
                                                        <InputNumber min={0} max={99} className="w-100" />
                                                        {/* <Select options={experienceKeyValue} /> */}
                                                    </Form.Item>
                                                </Col>
                                                <Col xxl={8} xl={12} xs={24}>
                                                    <Form.Item
                                                        label="Mobile No."
                                                        name="mobile_no"
                                                        rules={[formRules.mobile_no]}
                                                    >
                                                        <Input className="w-100" placeholder="Mobile" />
                                                    </Form.Item>
                                                </Col>

                                                <Col xxl={8} xl={12} xs={24}>
                                                    <Form.Item
                                                        label="Speciality"
                                                        name="speciality"
                                                        rules={[formRules.speciality]}
                                                    >
                                                        <Select
                                                            placeholder="Your specialization"
                                                            mode="multiple"
                                                            maxTagCount={1}
                                                            maxTagPlaceholder={(value) => `+ ${value.length} more ...`}
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            options={allSpecialities.map(
                                                                ({ speciality_name, _id }) => ({
                                                                    value: _id,
                                                                    label: speciality_name,
                                                                }),
                                                            )}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col xxl={8} xl={12} xs={24}>
                                                    <Form.Item label="Degree" name="degree" rules={[formRules.degree]}>
                                                        <Select
                                                            placeholder="Your Degrees"
                                                            mode="multiple"
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            options={allDegrees.map(({ degree_name, _id }) => ({
                                                                value: _id,
                                                                label: degree_name,
                                                            }))}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col xxl={16} xl={24} xs={24}>
                                                    <Form.Item label="About" name="about" rules={[formRules.about]}>
                                                        <Input.TextArea
                                                            placeholder="Skilled cardiologist with MBBS & MS, 3 years' expertise."
                                                            rows={3}
                                                            className="w-100"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Button loading={isUpdatingProfileData} type="primary" htmlType="submit">
                                                Save Change
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </>
                    </Fragment>
                );
            }}
        </Observer>
    );
};

export default EditDoctorProfile;
