import { LogoutOutlined } from "@ant-design/icons";
import { Button, Grid, Menu } from "antd";
import { SIDE_NAV_LIGHT } from "constants/ThemeConstant";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { onMobileNavToggle } from "store/slices/themeSlice";
import localStore from "store2";
import utils from "utils";
import Icon from "../util-components/Icon";
import IntlMessage from "../util-components/IntlMessage";

const { useBreakpoint } = Grid;

const setLocale = (localeKey, isLocaleOn = true) =>
    isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
    let keyList = [];
    let keyString = "";
    if (key) {
        const arr = key.split("-");
        for (let index = 0; index < arr.length; index++) {
            const elm = arr[index];
            index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
            keyList.push(keyString);
        }
    }
    return keyList;
};

const MenuItem = ({ title, icon, path }) => {
    const dispatch = useDispatch();

    const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");

    const closeMobileNav = () => {
        if (isMobile) {
            dispatch(onMobileNavToggle(false));
        }
    };

    return (
        <>
            {icon && <Icon type={icon} />}
            <span>{setLocale(title)}</span>
            {path && <Link onClick={closeMobileNav} to={path} />}
        </>
    );
};

const getSideNavMenuItem = (navItem) => {
    return navItem.map((nav) => {
        return {
            key: nav.key,
            label: <MenuItem title={nav.title} {...(nav.isGroupTitle ? {} : { path: nav.path, icon: nav.icon })} />,
            ...(nav.isGroupTitle ? { type: "group" } : {}),
            ...(nav.submenu.length > 0 ? { children: getSideNavMenuItem(nav.submenu) } : {}),
        };
    });
};

const getTopNavMenuItem = (navItem) =>
    navItem.map((nav) => {
        return {
            key: nav.key,
            label: <MenuItem title={nav.title} icon={nav.icon} {...(nav.isGroupTitle ? {} : { path: nav.path })} />,
            ...(nav.submenu.length > 0 ? { children: getTopNavMenuItem(nav.submenu) } : {}),
        };
    });

const SideNavContent = (props) => {
    const { routeInfo, hideGroupTitle } = props;
    const navigate = useNavigate();

    const sideNavTheme = useSelector((state) => state.theme.sideNavTheme);
    const mobileNav = useSelector((state) => state.theme.mobileNav);

    const { loginStore, globalStore, resetMainStore } = useMainStore();

    const navCollapsed = useSelector((state) => state.theme.navCollapsed);

    const handleClick = () => {
        // if (key === "Sign Out") {
        resetMainStore();
        localStore.clearAll();
        navigate("/login");
        // }
    };
    return (
        <Observer>
            {() => {
                const { userNavigation } = loginStore;
                const { isLoadingUserData, loggedInUserDetails } = globalStore;
                const [{ name, profile_pic }] = loggedInUserDetails;
                return (
                    <>
                        {/* <Card loading={isLoadingUserData} style={{ marginTop: 16 }} className={`m-2 mb-0 ${navCollapsed && "border-0"}`}>
                            <Row gutter={[16, 16]}>
                                <Col span={24} className="d-flex flex-column justify-content-center align-items-center">
                                    <Avatar
                                        src={profile_pic ? profile_pic : "/img/avatars/thumb-1.jpg"}
                                        style={{
                                            height: `${navCollapsed ? "50px" : "90px"}`,
                                            width: `${navCollapsed ? "50px" : "90px"}`,
                                        }}
                                        className=""
                                    />
                                    {!navCollapsed && (
                                        <div className="mt-2">
                                            <h4>{name ? name : ""}</h4>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Card> */}
                        <Menu
                            mode="inline"
                            theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
                            // style={{ height: "100%", borderRight: 0 }}
                            defaultSelectedKeys={[routeInfo?.key]}
                            defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
                            className={hideGroupTitle ? "hide-group-title" : ""}
                            items={userNavigation}
                        />
                        <div className="signout_button">
                            <Button onClick={handleClick}>
                                <LogoutOutlined className="mb-0" />{" "}
                                <span className={navCollapsed ? "d-none":"d-inline"}>Sign Out</span>
                            </Button>
                        </div>
                    </>
                );
            }}
        </Observer>
    );
};

// const TopNavContent = () => {
//   const topNavColor = useSelector((state) => state.theme.topNavColor);

//   const menuItems = useMemo(() => getTopNavMenuItem(navigationConfig), []);

//   return <Menu mode="horizontal" style={{ backgroundColor: topNavColor }} items={menuItems} />;
// };

const MenuContent = (props) => {
    // return props.type === NAV_TYPE_SIDE ? <SideNavContent {...props} /> : <TopNavContent {...props} />;
    return <SideNavContent {...props} />;
};

export default MenuContent;
