import { LogoutOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Menu, Space } from "antd";
import Icon from "components/util-components/Icon";
import { DATE_FORMAT_DDDD_DD_MMMM_YYYY_DAY_FULL, DATE_FORMAT_MMM_DD_YYYY_DAY_FULL } from "constants/DateConstant";
import { toJS } from "mobx";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOut } from "store/slices/authSlice";
import localStore from "store2";

const MenuItem = (props) => (
    <a className="d-flex align-items-center" href={props.path}>
        <Icon className="font-size-md" type={props.icon} />
        <span className="font-weight-normal mx-2">{props.label}</span>
    </a>
);

const MenuItemSignOut = (props) => (
        <Space className="d-flex align-items-center">
            <LogoutOutlined className="font-size-lg" />
            <span className="font-weight-normal mx-2">{props.label}</span>
        </Space>
);

export const NavProfile = () => {
    const { resetMainStore, globalStore } = useMainStore();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClick = ({ key }) => {
        if (key === "Sign Out") {
            resetMainStore();
            localStore.clearAll();
            navigate("/login");
            handleSignOut();
        }
    };
    const handleSignOut = () => {
        dispatch(signOut());
    };

    // const menu = (
    //     <Menu
    //         onClick={handleClick}
    //         items={[
    //             {
    //                 key: "Sign Out",
    //                 label: <MenuItemSignOut label="Sign Out" />,
    //             },
    //         ]}
    //     />
    // );

    return (
        <>
            <Observer>
                {() => {
                    const { headerNavProfileDetail } = globalStore;
                    const { userName, userDegree, userProfile } = headerNavProfileDetail;
                    return (
                        <Dropdown placement="bottomRight" overlay={""} trigger={["click"]}>
                            <div className="nav-item">
                                <div className="d-flex align-items-center">
                                    <Avatar src={userProfile} />
                                    <div className="pl-2 d-none d-sm-block profile-text">
                                        <div className="font-size-base font-weight-bold">Welcome, {userName}</div>
                                        <span className="opacity-0-8">
                                            {moment().format(DATE_FORMAT_DDDD_DD_MMMM_YYYY_DAY_FULL)}
                                        </span>
                                    </div>
                                    {/* <MenuItemSignOut label="" /> */}
                                </div>
                            </div>
                        </Dropdown>
                    );
                }}
            </Observer>
        </>
    );
};

export default NavProfile;
