import { loadStripe } from "@stripe/stripe-js";
import { Button, Col, Modal, Row } from "antd";
import { CardSVG } from "assets/svg/icon";
import PayPalComponent from "components/payment/PayPal";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import { useLocation, useNavigate } from "react-router-dom";
import { getLocalStorageItems } from "utils/utilities";

const PaymentModal = () => {
    const { patientStore, patientProfileStore } = useMainStore();
    const { user_id: patientId } = getLocalStorageItems({ get: true, key: "user_id" });
    const location = useLocation();
    const { doctorId} = location.state;
    const navigate = useNavigate();

    const handleBooking = async (val) => {
        const { isAppointmentFormData, bookAnAppointment, setIsConfirmingBooking, setIsPaymentOptionModal } =
            patientStore;

        try {
            setIsConfirmingBooking(true);
            if (val == "offline") {
                isAppointmentFormData.paymentMode = val;
                await bookAnAppointment(patientId, doctorId, isAppointmentFormData)
                    .then(async ({ success, data }) => {
                        const [{ appointmentData }] = data;
                        const [{ appointment_id }] = appointmentData;
                        if (success) {
                            navigate(`/patient/view-appointment?pId=${patientId}&aId=${appointment_id}&type=upcoming`);
                        }
                        setIsPaymentOptionModal();
                    })
                    .catch((error) => {
                        setIsConfirmingBooking();
                    })
                    .finally(() => setIsConfirmingBooking());
            } else {
                isAppointmentFormData.paymentMode = "online";
                await bookAnAppointment(patientId, doctorId, isAppointmentFormData)
                    .then(async ({ data }) => {
                        const [{ paymentSessionData }] = data;
                        const stripePromise = loadStripe(process.env.REACT_APP_STRIP_KEY);
                        const stripe = await stripePromise;

                        stripe.redirectToCheckout({
                            sessionId: paymentSessionData.id,
                        });
                    })
                    .catch((error) => {
                        setIsConfirmingBooking();
                    })
                    .finally(() => setIsConfirmingBooking());
            }
        } catch (error) {
            setIsConfirmingBooking();
        }
    };


    return (
        <Observer>
            {() => {
                const {
                    isAppointmentFormData,
                    isConfirmingBooking,
                    isPaymentOptionModal,
                    setIsPaymentOptionModal,
                    paymentOptions,
                } = patientStore;
                const { appointmentMode } = isAppointmentFormData;
                const { doctorsMethods = [], adminMethods = [] } = paymentOptions;
                const [online = "", offline = ""] = doctorsMethods;
                return (
                    <>
                        <Modal
                            title="Payment"
                            open={isPaymentOptionModal}
                            footer={false}
                            onCancel={() => setIsPaymentOptionModal()}
                            width={1000}
                            maskClosable={false}
                        >
                            <div className ="ac-container">
                                {online &&
                                    adminMethods.map(({ name }) => {
                                        return (
                                            <>
                                                {name == "stripe" && (
                                                    <div className="radio_position">
                                                        <input
                                                            id="ac-2"
                                                            name="accordion-1"
                                                            type="radio"
                                                            value={"stripe"}
                                                            defaultChecked
                                                        />
                                                        <label htmlFor="ac-2">Stripe</label>
                                                        <article className ="ac-medium">
                                                            <Row
                                                                justify="center"
                                                                className="flex-row"
                                                                gutter={[16, 32]}
                                                            >
                                                                <Col
                                                                    span={24}
                                                                    className="d-flex justify-content-center"
                                                                >
                                                                    <CardSVG />
                                                                </Col>
                                                                <Col span={8} className="d-flex justify-content-center">
                                                                    <p className="text-center">
                                                                        After clicking “Pay now”, you will be redirected
                                                                        to Stripe Secure to complete your payment
                                                                        securely.
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                            <Row justify="end">
                                                                <Button
                                                                    loading={isConfirmingBooking}
                                                                    type="primary"
                                                                    onClick={() => handleBooking("stripe")}
                                                                >
                                                                    Pay Now
                                                                </Button>
                                                            </Row>
                                                        </article>
                                                    </div>
                                                )}
                                                {name == "paypal" && (
                                                    <div className="radio_position">
                                                        <input
                                                            id="ac-3"
                                                            name="accordion-1"
                                                            type="radio"
                                                            value={"paypal"}
                                                        />
                                                        <label htmlFor="ac-3">Paypal</label>
                                                        <article className ="ac-large">
                                                            <Row justify="center">
                                                                <Col span={12}>
                                                                    <PayPalComponent />
                                                                </Col>
                                                            </Row>
                                                        </article>
                                                    </div>
                                                )}
                                            </>
                                        );
                                    })}
                                {offline && appointmentMode == "offline" && (
                                    <div className="radio_position">
                                        <input id="ac-4" name="accordion-1" type="radio" value={"offline"} />
                                        <label htmlFor="ac-4">Pay in clinic</label>
                                        <article className ="ac-large">
                                            <Row justify="end">
                                                <Col>
                                                    <Button
                                                        type="primary"
                                                        loading={isConfirmingBooking}
                                                        onClick={() => handleBooking("offline")}
                                                    >
                                                        Book
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </article>
                                    </div>
                                )}
                            </div>
                        </Modal>
                    </>
                );
            }}
        </Observer>
    );
};

export default PaymentModal;
