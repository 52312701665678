import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import Loading from "components/shared-components/Loading";
import { ROW_GUTTER } from "constants/ThemeConstant";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import { Fragment, useEffect } from "react";
import useFirstLogin from "utils/hooks/useFirstLogin";
import { getLocalStorageItems } from "utils/utilities";

const DoctorFeesStructure = () => {
    const { doctorProfileStore } = useMainStore();
    const isFirstLogin = useFirstLogin();

    const amountRule = (rule, value, callback) => {
        if (value && value <= 0) {
            callback("Please enter a valid amount");
        } else {
            callback();
        }
    };

    const formRules = {
        offline_new_case_fees: {
            required: true,
            message: "This field is required!",
        },
        offline_ongoing_case_fees: {
            required: true,
            message: "This field is required!",
        },
        online_new_case_fees: {
            required: true,
            message: "This field is required!",
        },
        online_ongoing_case_fees: {
            required: true,
            message: "This field is required!",
        },
        accepted_payment_mode: {
            required: true,
            message: "This field is required!",
        },
    };

    useEffect(() => {
        const { getDoctorFeesDetailsById, setIsLoadingDoctorFees } = doctorProfileStore;
        const { user_id: doctorId } = getLocalStorageItems({ get: true, key: "user_id" });
        setIsLoadingDoctorFees(true);
        getDoctorFeesDetailsById(doctorId)
            .then(() => {})
            .finally(() => setIsLoadingDoctorFees());
    }, []);

    const handleFeesInfoForm = async (feesInfo) => {
        const { addOrUpdateFeesStructure, setIsUpdatingFeesStructure } = doctorProfileStore;

        const { user_id: doctorId, first_login } = getLocalStorageItems({
            getAll: true,
            key: ["user_id", "step", "first_login"],
        });
        setIsUpdatingFeesStructure(true);
        await addOrUpdateFeesStructure({ doctorId, feesInfo }).finally(() => {
            setIsUpdatingFeesStructure();
            isFirstLogin(first_login, 2);
        });
    };

    return (
        <Observer>
            {() => {
                const { doctorFeesStructure, isLoadingDoctorFees, isUpdatingFeesStructure } = doctorProfileStore;
                const [
                    {
                        offline_new_case_fees,
                        offline_ongoing_case_fees,
                        online_new_case_fees,
                        online_ongoing_case_fees,
                        accepted_payment_mode,
                    },
                ] = doctorFeesStructure;

                if (isLoadingDoctorFees) {
                    return <Loading />;
                }

                return (
                    <Fragment>
                        <h2 className="mb-4">Fees Structure</h2>
                        <Form
                            layout="vertical"
                            name="fees-structure"
                            onFinish={handleFeesInfoForm}
                            initialValues={{
                                offline_new_case_fees,
                                offline_ongoing_case_fees,
                                online_new_case_fees,
                                online_ongoing_case_fees,
                                accepted_payment_mode,
                            }}
                        >
                            <Row>
                                <Col span={24}>
                                    <Row gutter={ROW_GUTTER}>
                                        {/* FEES SECTION */}
                                        <>
                                            <Col span={24}>
                                                <Row gutter={[16, 8]}>
                                                    <Col span={24}>
                                                        <h4 className="mb-0 text-gray" level={5}>
                                                            Offline
                                                        </h4>
                                                    </Col>
                                                    <Col xxl={6} xl={6} xs={24}>
                                                        <Form.Item
                                                            label="New Case Fees"
                                                            name="offline_new_case_fees"
                                                            rules={[
                                                                formRules.offline_new_case_fees,
                                                                { validator: amountRule },
                                                            ]}
                                                        >
                                                            <Input className="no-spinners" type="number" prefix="$" />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col xxl={6} xl={6} xs={24}>
                                                        <Form.Item
                                                            label="Ongoing Case Fees"
                                                            name="offline_ongoing_case_fees"
                                                            rules={[
                                                                formRules.offline_ongoing_case_fees,
                                                                { validator: amountRule },
                                                            ]}
                                                        >
                                                            <Input className="no-spinners" type="number" prefix="$" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={24}>
                                                <Row gutter={[16, 8]}>
                                                    <Col span={24}>
                                                        <h4 className="mb-0 text-gray" level={5}>
                                                            Online
                                                        </h4>
                                                    </Col>
                                                    <Col xxl={6} xl={6} xs={24}>
                                                        <Form.Item
                                                            label=" New Case Fees"
                                                            name="online_new_case_fees"
                                                            rules={[
                                                                formRules.online_new_case_fees,
                                                                { validator: amountRule },
                                                            ]}
                                                        >
                                                            <Input className="no-spinners" type="number" prefix="$" />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col xxl={6} xl={6} xs={24}>
                                                        <Form.Item
                                                            label="Ongoing Case Fees"
                                                            name="online_ongoing_case_fees"
                                                            rules={[
                                                                formRules.online_ongoing_case_fees,
                                                                { validator: amountRule },
                                                            ]}
                                                        >
                                                            <Input className="no-spinners" type="number" prefix="$" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </>
                                    </Row>
                                    <Row gutter={ROW_GUTTER}>
                                        <>
                                            <Col xxl={6} xl={6} xs={24}>
                                                <Form.Item
                                                    label="Payment Acceptance Mode"
                                                    name="accepted_payment_mode"
                                                    rules={[formRules.accepted_payment_mode]}
                                                >
                                                    <Checkbox.Group
                                                        options={[
                                                            { label: "Online", value: "online" },
                                                            { label: "Offline", value: "offline" },
                                                        ]}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </>
                                    </Row>
                                    <Button loading={isUpdatingFeesStructure} type="primary" htmlType="submit">
                                        Save Change
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Fragment>
                );
            }}
        </Observer>
    );
};

export default DoctorFeesStructure;
