import { Button, Card, Col, DatePicker, Empty, Form, Modal, Radio, Row, Skeleton, Space, Tag } from "antd";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { Fragment, useEffect } from "react";
import { FaSquare } from "react-icons/fa";
import { isValidArray, isValidObject } from "utils/utilities";

const RescheduleAppointmentModal = ({ appointmentId, doctorId, patientId, appointmentDetails }) => {
    const { patientStore } = useMainStore();
    const [slotForm] = Form.useForm();

    useEffect(() => {
        const {
            getDoctorsAvailableSlots,
            setIsLoadingDoctorAvailableSlots,
            setIsAppointmentIdForActive,
        } = patientStore;
        const selectedDate = { date: moment().format("DD-MM-YYYY") };

        setIsAppointmentIdForActive(null);
        setIsLoadingDoctorAvailableSlots(true);
        getDoctorsAvailableSlots(doctorId, selectedDate).finally(() => setIsLoadingDoctorAvailableSlots(false));
    }, []);


    const rescheduleSlot = async () => {
        try {
            const { UpdateAppointmentSlot, setIsLoadingForUpdateAppointment, setIsRescheduleAppointmentModal } = patientStore;
            await slotForm
                .validateFields()
                .then(({ date, time }) => {
                    const appointmentData = {
                        date: moment(date).format("DD-MM-YYYY"),
                        time,
                    };

                    setIsLoadingForUpdateAppointment(true);
                    UpdateAppointmentSlot(patientId, doctorId, appointmentId, appointmentData).then(
                        async ({ data, success }) => {
                            try {
                                if (success) {
                                    await appointmentDetails()
                                    setIsLoadingForUpdateAppointment();
                                    setIsRescheduleAppointmentModal();

                                };
                            } catch (error) { console.log('error', error) }

                        },
                    );
                })
                .finally(() => {
                    setIsLoadingForUpdateAppointment();
                })
                .catch((err) => {
                    console.log("err", err);
                });
        } catch (error) {
            console.log("🚀 ~ rescheduleSlot ~ error:", error);
        }
    };

    const onChange = (value) => {
        const {
            getDoctorsAvailableSlots,
            setIsLoadingDoctorAvailableSlots,
            setIsSelectedDateByCalender,
            setIsAppointmentIdForActive
        } = patientStore;
        setIsSelectedDateByCalender(value);
        setIsAppointmentIdForActive(null)
        setIsLoadingDoctorAvailableSlots(true);
        const selectedDate = { date: moment(value).format("DD-MM-YYYY") };
        getDoctorsAvailableSlots(doctorId, selectedDate).finally(() => setIsLoadingDoctorAvailableSlots(false));
    };

    const getSlotOption = (value) => {
        const { setGetSlotsByTime } = patientStore;
        setGetSlotsByTime(value.target.value);
    };
    const disabledDate = (current) => {
        const currentDate = new Date();
        return (current && current.year() > currentDate.getFullYear()) || current < currentDate.setHours(0, 0, 0, 0);
    };

    const currentTime = moment();

    return (
        <Observer>
            {() => {
                const {
                    isAppointmentIdForActive,
                    doctorAvailableSlots,
                    isLoadingDoctorAvailableSlots,
                    isSelectedDateByCalender,
                    setIsAppointmentIdForActive,
                    isRescheduleAppointmentModal,
                    setIsRescheduleAppointmentModal,
                    isLoadingForUpdateAppointment
                } = patientStore;

                const [{ availableTimings, availability }] = doctorAvailableSlots;

                return (
                    <Modal
                        title="Reschedule Appointment"
                        footer={false}
                        open={isRescheduleAppointmentModal}
                        onCancel={() => setIsRescheduleAppointmentModal(false)}
                        onOk={() => rescheduleSlot()}
                        width={1000}
                    >
                        <Row className="mt-3" gutter={[16, 16]}>

                            <Col span={24}>
                                <Form name="slotForm" form={slotForm} layout="vertical">

                                    <div className="d-flex justify-content-between">
                                        <h2>{isSelectedDateByCalender.format("DD MMMM YYYY, dddd")}</h2>
                                        <div>
                                            <Form.Item
                                                name={"date"}
                                                label="Select date"
                                            >
                                                <DatePicker
                                                    onChange={(e) => onChange(e)}
                                                    allowClear={false}
                                                    disabledDate={disabledDate}
                                                    defaultValue={isSelectedDateByCalender}
                                                    format={"DD-MM-YYYY"}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <>
                                        <div className="d-flex justify-content-between align-items-xl-center align-items-start my-3">
                                            <div className="d-xl-flex align-items-center">
                                                <span className="d-flex align-items-center mr-3 mb-xl-0 mb-2">
                                                    <FaSquare size={20} color="#FAFAFA" className="border mr-2" />
                                                    <span>Available</span>
                                                </span>
                                                <span className="d-flex align-items-center">
                                                    <FaSquare size={20} color="#9DAECF" className="border mr-2" />
                                                    <span>Already Booked</span>
                                                </span>
                                            </div>
                                        </div>
                                        <Card
                                            bodyStyle={{
                                                height: "350px",
                                                overflowY: "scroll",
                                            }}
                                            className="slot_card"
                                        >
                                            {isValidObject(doctorAvailableSlots) && isValidArray(availableTimings) ? (
                                                <>
                                                    <Form.Item
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "This field is required",
                                                            },
                                                        ]}
                                                        name={"time"}
                                                        label="Select Time Slot"
                                                    >
                                                        <Radio.Group
                                                            name="time"
                                                            onChange={(val) =>
                                                                setIsAppointmentIdForActive(val.target.id)
                                                            }
                                                        >
                                                            <Space wrap>
                                                                {availableTimings?.map(({ time, isBooked }, _ind) => {
                                                                    const [startTime, endTime] = time?.split("-");
                                                                    const localStartTime = moment
                                                                        .utc(startTime, "hh:mm A")
                                                                        .local();
                                                                    const localEndTime = moment
                                                                        .utc(endTime, "hh:mm A")
                                                                        .local();
                                                                    const val = currentTime.isBefore(localStartTime);
                                                                    return !isLoadingDoctorAvailableSlots ? (
                                                                        !isSelectedDateByCalender.isSame(
                                                                            currentTime,
                                                                            "day",
                                                                        ) ? (
                                                                            <Fragment key={_ind}>
                                                                                <Radio
                                                                                    className="book_appointment_radio"
                                                                                    value={time}
                                                                                    disabled={isBooked}
                                                                                    id={_ind}
                                                                                >
                                                                                    <Tag
                                                                                        color={
                                                                                            _ind ==
                                                                                                isAppointmentIdForActive
                                                                                                ? "processing"
                                                                                                : isBooked
                                                                                                    ? "#9DAECF"
                                                                                                    : ""
                                                                                        }
                                                                                        disabled={isBooked}
                                                                                        size={"large"}
                                                                                        className={`${!isBooked
                                                                                            ? "cursor_pointer"
                                                                                            : ""
                                                                                            } px-4 py-1 w-100`}
                                                                                    >
                                                                                        <span className="mr-2">
                                                                                            {moment
                                                                                                .utc(
                                                                                                    startTime,
                                                                                                    "hh:mm A",
                                                                                                )
                                                                                                .local()
                                                                                                .format("hh:mm A")}
                                                                                        </span>
                                                                                        <span>-</span>
                                                                                        <span className="ml-2">
                                                                                            {moment
                                                                                                .utc(endTime, "hh:mm A")
                                                                                                .local()
                                                                                                .format("hh:mm A")}
                                                                                        </span>
                                                                                    </Tag>
                                                                                </Radio>
                                                                            </Fragment>
                                                                        ) : val ? (
                                                                            <Fragment key={_ind}>
                                                                                <Radio
                                                                                    className="book_appointment_radio"
                                                                                    value={time}
                                                                                    disabled={isBooked}
                                                                                    id={_ind}
                                                                                >
                                                                                    <Tag
                                                                                        color={
                                                                                            _ind ==
                                                                                                isAppointmentIdForActive
                                                                                                ? "processing"
                                                                                                : isBooked
                                                                                                    ? "#9DAECF"
                                                                                                    : ""
                                                                                        }
                                                                                        disabled={isBooked}
                                                                                        size={"large"}
                                                                                        className={`${!isBooked
                                                                                            ? "cursor_pointer"
                                                                                            : ""
                                                                                            } px-4 py-1 w-100`}
                                                                                    >
                                                                                        <span className="mr-2">
                                                                                            {moment
                                                                                                .utc(
                                                                                                    startTime,
                                                                                                    "hh:mm A",
                                                                                                )
                                                                                                .local()
                                                                                                .format("hh:mm A")}
                                                                                        </span>
                                                                                        <span>-</span>
                                                                                        <span className="ml-2">
                                                                                            {moment
                                                                                                .utc(endTime, "hh:mm A")
                                                                                                .local()
                                                                                                .format("hh:mm A")}
                                                                                        </span>
                                                                                    </Tag>
                                                                                </Radio>
                                                                            </Fragment>
                                                                        ) : (
                                                                            availableTimings.length == 1 + _ind &&
                                                                            !isLoadingDoctorAvailableSlots && (
                                                                                <Row justify="center">
                                                                                    <Empty
                                                                                        description="No slots available on this time"
                                                                                        className="text-center"
                                                                                    />
                                                                                </Row>
                                                                            )
                                                                        )
                                                                    ) : (
                                                                        <Skeleton.Input
                                                                            className=" px-2 py-1 w-100"
                                                                            active={true}
                                                                            size={"small"}
                                                                        />
                                                                    );
                                                                })}
                                                            </Space>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </>
                                            ) : (
                                                <Empty description="No slots for this day cycle" />
                                            )}
                                        </Card>

                                    </>

                                </Form>
                            </Col>
                        </Row>
                        <Row justify="end">
                            <Space>
                                <Button onClick={() => setIsRescheduleAppointmentModal(false)}>
                                    Cancel
                                </Button>
                                <Button type="primary" onClick={() => rescheduleSlot()} loading={isLoadingForUpdateAppointment}>
                                    Submit
                                </Button>
                            </Space>

                        </Row>
                    </Modal>
                );
            }}
        </Observer>
    );
};

export default RescheduleAppointmentModal;
