import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const GoBack = () => {
    const navigate = useNavigate();
    return (
        <Button shape="circle" onClick={() => navigate(-1)}>
            <ArrowLeftOutlined />
        </Button>
    )
}

export default GoBack
