import { Card, Col, Modal, Row, Space, Tag } from "antd";
import Loading from "components/shared-components/Loading";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { isValidArray } from "utils/utilities";

const ProfileModal = () => {
    const { patientStore, patientProfileStore } = useMainStore();
    const { setIsLoadingDoctorsProfile } = patientStore;
    const arr = ["#f50", "#2db7f5", "#87d068", "#108ee9", "#845068", "#120fd2", "#5E45D5"];
    const navigate = useNavigate();

    return (
        <Observer>
            {() => {
                const {
                    doctorDetails,
                    doctorProfileDataForPatient,
                    isLoadingDoctorsProfile,
                    isModalOpen,
                    setIsModalOpen,
                } = patientStore;

                const [
                    {
                        doctorProfile = {},
                        speciality = [],
                        degrees = [],
                        clinicInfo = {},
                        availability = [],
                        fees = {},
                    },
                ] = doctorDetails;

                const {
                    doctor_id,
                    profile_pic,
                    name,
                    dob,
                    gender,
                    about,
                    mobile_no,
                    experience,
                    email = "",
                } = doctorProfile;

                const { address, address_url, city, country, state, zipCode } = clinicInfo;
                const {
                    offline_new_case_fees,
                    offline_ongoing_case_fees,
                    online_new_case_fees,
                    online_ongoing_case_fees,
                    accepted_payment_mode,
                } = fees;

                return (
                    <Modal
                        centered
                        open={isModalOpen}
                        onCancel={() => setIsModalOpen()}
                        width={700}
                        okText="Book"
                        onOk={() => {
                            setIsModalOpen();
                            setIsLoadingDoctorsProfile(true);
                            navigate(`/patient/book-appointment`, {
                                state: {
                                    doctorId: doctor_id,
                                    doctorName: name,
                                    doctorAddress: address,
                                    locationUrl: address_url,
                                },
                            });
                        }}
                    >
                        {!isLoadingDoctorsProfile ? (
                            <Card bordered={false} className="doctor_card">
                                <Row justify="center" align="middle" className="pb-4 rounded-circle">
                                    <Col span={6}>
                                        <img
                                            className=" rounded-circle"
                                            width={120}
                                            height={120}
                                            src={profile_pic ? profile_pic : "/img/avatars/thumb-1.jpg"}
                                        />
                                    </Col>
                                    <Col span={18}>
                                        <Row className="" justify="space-between" align="middle" gutter={[32, 16]}>
                                            <Col span={24}>
                                                <h2 className="job_heading mb-0">
                                                    {name}
                                                    {", "}
                                                    {degrees.map(({ degree_name, degree_id }) => {
                                                        return (
                                                            <small
                                                                key={degree_id}
                                                                className="text-uppercase font-size-md font-weight-lighter"
                                                            >
                                                                {degree_name}{" "}
                                                            </small>
                                                        );
                                                    })}
                                                </h2>
                                                <p className="job_location">{address}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <hr />

                                <Row className="pt-4" justify="space-between" align="" gutter={[32, 16]}>
                                    <Col span={8}>
                                        <Col span={24} className="p-0">
                                            <h6 className="job_detail">Email:</h6>
                                            <p className="job_detail1 ml-2"> {email}</p>
                                        </Col>
                                        <Col span={24} className="p-0">
                                            <h6 className="job_detail mt-4">Phone No:</h6>
                                            <p className="job_detail1">{mobile_no}</p>
                                        </Col>
                                        <Col span={24} className="p-0">
                                            <h6 className="job_detail mt-4">Gender:</h6>
                                            <p className="job_detail1 text-capitalize">{gender}</p>
                                        </Col>
                                    </Col>
                                    <Col span={8}>
                                        <Col span={24} className="p-0">
                                            <h6 className="job_detail">Experiences:</h6>
                                            <p className="job_detail1">{experience} Year</p>
                                        </Col>
                                        <Col span={24} className="p-0">
                                            <h6 className="job_detail mt-4">Fees /New Case:</h6>
                                            <p className="job_detail1">$ {offline_new_case_fees} </p>
                                        </Col>
                                        <Col span={24} className="p-0">
                                            <h6 className="job_detail mt-4">Fees/Ongoing Case:</h6>
                                            <p className="job_detail1">$ {offline_ongoing_case_fees} </p>
                                        </Col>
                                    </Col>

                                    <Col span={8}>
                                        <Col span={24} className="p-0">
                                            {isValidArray(availability) && (
                                                <>
                                                    <h6 className="job_detail">Available</h6>

                                                    {availability?.map(({ day, startTime, endTime }) => {
                                                        return (
                                                            <Fragment key={day}>
                                                                <div className="d-flex justify-content-between mb-2">
                                                                    <p className="job_detail1 font-size-sm font-weight-bolder">
                                                                        {day.slice(0, 3)}
                                                                    </p>
                                                                    <small className="job_detail1 font-size-sm ">
                                                                        {startTime && endTime
                                                                            ? `${moment
                                                                                  .utc(startTime)
                                                                                  .local()
                                                                                  .format("hh:mm A")} - ${moment
                                                                                  .utc(endTime)
                                                                                  .local()
                                                                                  .format("hh:mm A")}`
                                                                            : "-"}
                                                                    </small>
                                                                </div>
                                                            </Fragment>
                                                        );
                                                    })}
                                                </>
                                            )}
                                        </Col>
                                    </Col>
                                    {isValidArray(speciality) && (
                                        <Col span={24}>
                                            <h6 className="job_detail mt-2 mb-2">Speciality:</h6>
                                            <Space size={[0, 8]} wrap>
                                                {speciality.map((data, ind) => {
                                                    return (
                                                        <Tag color={arr[ind]} key={ind}>
                                                            {data?.speciality_name}
                                                        </Tag>
                                                    );
                                                })}
                                            </Space>
                                        </Col>
                                    )}
                                </Row>

                                <Row className="mt-4">
                                    <p className="text-start">{about}</p>
                                </Row>
                            </Card>
                        ) : (
                            <Loading />
                        )}
                    </Modal>
                );
            }}
        </Observer>
    );
};
export default ProfileModal;
