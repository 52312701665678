import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Drawer, Form, Input, InputNumber, Radio, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { DIR_RTL } from "constants/ThemeConstant";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import { Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { isValidArray } from "utils/utilities";

const AddPrescriptionForm = ({ open, onClose, direction }) => {
    const [prescriptionForm] = Form.useForm();

    const { state } = useLocation();

    const { prescriptionStore } = useMainStore();

    useEffect(() => {
        const { getPrescriptionByAppointmentId, setIsLoadingPrescription, setPrescriptionData } = prescriptionStore;
        setIsLoadingPrescription(true);
        getPrescriptionByAppointmentId(state.appointmentId)
            .then((res) => {})
            .finally(() => setIsLoadingPrescription());

        return () => setPrescriptionData();
    }, []);

    const handleFormSubmit = async () => {
        const { addPrescription, setIsUpdatingPrescription } = prescriptionStore;
        prescriptionForm
            .validateFields()
            .then(async (data) => {
                setIsUpdatingPrescription(true);
                return await addPrescription({
                    formData: data,
                    userData: { appointmentId: appointmentId, doctorId, patientId },
                });
            })
            .then((success, data) => {
                if (success) onClose();
            })
            .catch((err) => console.log(err.message))
            .finally(() => {
                setIsUpdatingPrescription();
            });

        const { appointmentId, patientId, doctorId } = state;
    };

    return (
        <Observer>
            {() => {
                const { prescriptionData, isUpdatingPrescription, isLoadingPrescription } = prescriptionStore;

                const { medications = [], testReports = [] } = prescriptionData;

                const initialMedicationData = medications.map(
                    ({
                        medicineType = "",
                        medicineName = "",
                        medicineStrength = "",
                        medicineDose = "",
                        intakeDuration = "",
                        toBeTaken = "",
                        medicineIntakeTime = [],
                        importantNote = "",
                    }) => ({
                        medicineType,
                        medicineName,
                        medicineStrength: medicineStrength.split(" ")[0],
                        strengthUnit: medicineStrength.split(" ")[1],
                        medicineDose: medicineDose.split(" ")[0],
                        doseUnit: medicineDose.split(" ")[1],
                        intakeDuration: intakeDuration.split(" ")[0],
                        intakeUnit: intakeDuration.split(" ")[1],
                        toBeTaken,
                        medicineIntakeTime,
                        importantNote,
                    }),
                );

                const initialTestReportsData = testReports.map((report) => ({ report }));

                prescriptionForm.setFieldsValue({
                    medications: initialMedicationData,
                    testReports: isValidArray(initialTestReportsData) ? initialTestReportsData : [{}],
                });

                return (
                    <Fragment>
                        <Drawer
                            title="Add Prescription"
                            placement={direction === DIR_RTL ? "left" : "right"}
                            width={450}
                            onClose={onClose}
                            open={open}
                            extra={
                                <>
                                    <Button
                                        className="w-100"
                                        loading={isUpdatingPrescription}
                                        type="primary"
                                        htmlType="submit"
                                        onClick={handleFormSubmit}
                                    >
                                        Save
                                    </Button>
                                </>
                            }
                        >
                            <Form
                                name="add_prescription_form"
                                autoComplete="off"
                                form={prescriptionForm}
                                layout="vertical"
                                onFinish={handleFormSubmit}
                                initialValues={{ medications: [{}], testReports: [{}] }}
                            >
                                <Row>
                                    {/* Test Reports */}
                                    <h4>Test Reports</h4>
                                    <Col span={24}>
                                        <Card className="report_wrapper">
                                            <Form.List name="testReports">
                                                {(fields, { add, remove }) => (
                                                    <>
                                                        <Row justify="space-between" align="middle">
                                                            {fields.map(({ key, name, ...restField }, index) => (
                                                                <Fragment key={key}>
                                                                    <Col span={index === 0 ? 24 : 20}>
                                                                        <Form.Item
                                                                            placeholder="Ex- CBC RBC"
                                                                            className="mb-3"
                                                                            {...restField}
                                                                            name={[name, "report"]}
                                                                            size="small"
                                                                        >
                                                                            <Input
                                                                                placeholder={`Test Report ${index + 1}`}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col
                                                                        span={index === 0 ? 0 : 3}
                                                                        // className="d-flex flex-column-reverse align-items-end"
                                                                    >
                                                                        {index !== 0 && (
                                                                            <DeleteOutlined
                                                                                className="report_icon mb-3"
                                                                                onClick={() => remove(name)}
                                                                            />
                                                                        )}
                                                                    </Col>
                                                                </Fragment>
                                                            ))}
                                                        </Row>
                                                        <Row justify="end">
                                                            <Col span={3}>
                                                                <Form.Item>
                                                                    {/* <Button
                                                                        type="primary"
                                                                        ghost
                                                                        size="small"
                                                                        shape="square"
                                                                        block
                                                                        icon={<PlusOutlined />}
                                                                    ></Button> */}
                                                                    <PlusOutlined
                                                                        className="text-primary add_icon"
                                                                        onClick={() => add()}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}
                                            </Form.List>
                                        </Card>
                                    </Col>

                                    {/* Medications */}
                                    <h4>Medications</h4>
                                    <Col span={24}>
                                        <Form.List name="medications">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }, index) => (
                                                        <Card key={key} title={`Medicine ${index + 1}`}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Row gutter={[16, 16]}>
                                                                        {/* Medicine Name */}
                                                                        <Col span={24}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[name, "medicineName"]}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message:
                                                                                            "Missing medicine name",
                                                                                    },
                                                                                ]}
                                                                                label="Medicine Name"
                                                                            >
                                                                                <Input placeholder="Medicine Name" />
                                                                            </Form.Item>
                                                                        </Col>

                                                                        {/* medicineType */}
                                                                        <Col span={12}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[name, "medicineType"]}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message:
                                                                                            "Missing medicine type",
                                                                                    },
                                                                                ]}
                                                                                label="Medicine Type"
                                                                            >
                                                                                <Select
                                                                                    className="w-100"
                                                                                    placeholder="Select Medicine Type"
                                                                                    options={[
                                                                                        {
                                                                                            value: "liquid",
                                                                                            label: "Liquid",
                                                                                        },
                                                                                        {
                                                                                            value: "tablet",
                                                                                            label: "Tablet",
                                                                                        },
                                                                                        {
                                                                                            value: "injections",
                                                                                            label: "Injections",
                                                                                        },
                                                                                        {
                                                                                            value: "drops",
                                                                                            label: "Drops",
                                                                                        },
                                                                                    ]}
                                                                                />
                                                                            </Form.Item>
                                                                        </Col>

                                                                        {/* medicineStrength */}
                                                                        <Col span={12}>
                                                                            <Form.Item
                                                                                label="Strength"
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message: "",
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <Input.Group compact>
                                                                                    <Form.Item
                                                                                        {...restField}
                                                                                        name={[
                                                                                            name,
                                                                                            "medicineStrength",
                                                                                        ]}
                                                                                        noStyle
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                message:
                                                                                                    "Missing medicine power",
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <InputNumber
                                                                                            type="number"
                                                                                            controls={false}
                                                                                            placeholder="500mg"
                                                                                            style={{
                                                                                                width: "50%",
                                                                                            }}
                                                                                        />
                                                                                    </Form.Item>
                                                                                    <Form.Item
                                                                                        {...restField}
                                                                                        name={[name, "strengthUnit"]}
                                                                                        noStyle
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                message: "",
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <Select
                                                                                            placeholder="Select Unit"
                                                                                            style={{
                                                                                                width: "50%",
                                                                                            }}
                                                                                        >
                                                                                            <Select.Option value="mg">
                                                                                                mg
                                                                                            </Select.Option>
                                                                                            <Select.Option value="mL">
                                                                                                mL
                                                                                            </Select.Option>
                                                                                            <Select.Option value="mcg">
                                                                                                mcg
                                                                                            </Select.Option>
                                                                                        </Select>
                                                                                    </Form.Item>
                                                                                </Input.Group>
                                                                            </Form.Item>
                                                                        </Col>

                                                                        {/* Medicine Dose */}
                                                                        <Col span={12}>
                                                                            <Form.Item
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                    },
                                                                                ]}
                                                                                label="Medicine Dose"
                                                                            >
                                                                                <Input.Group compact className="d-flex">
                                                                                    <Form.Item
                                                                                        {...restField}
                                                                                        name={[name, "medicineDose"]}
                                                                                        noStyle
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                message:
                                                                                                    "This field is required",
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <Select
                                                                                            placeholder="Select Dose"
                                                                                            style={{
                                                                                                width: "50%",
                                                                                            }}
                                                                                        >
                                                                                            {Array(10)
                                                                                                .fill()
                                                                                                .map((_, index) => (
                                                                                                    <Select.Option
                                                                                                        key={index}
                                                                                                        value={
                                                                                                            index + 1
                                                                                                        }
                                                                                                    >
                                                                                                        {index + 1}
                                                                                                    </Select.Option>
                                                                                                ))}
                                                                                        </Select>
                                                                                    </Form.Item>
                                                                                    <Form.Item
                                                                                        {...restField}
                                                                                        name={[name, "doseUnit"]}
                                                                                        noStyle
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                message: "",
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <Select
                                                                                            placeholder="Select Unit"
                                                                                            style={{
                                                                                                width: "50%",
                                                                                            }}
                                                                                        >
                                                                                            <Select.Option value="tablet">
                                                                                                Tablet
                                                                                            </Select.Option>
                                                                                            <Select.Option value="mL">
                                                                                                mL
                                                                                            </Select.Option>
                                                                                            <Select.Option value="drops">
                                                                                                Drops
                                                                                            </Select.Option>
                                                                                        </Select>
                                                                                    </Form.Item>
                                                                                </Input.Group>
                                                                            </Form.Item>
                                                                        </Col>

                                                                        {/* Intake Duration */}
                                                                        <Col span={12}>
                                                                            <div className="medicine_duration">
                                                                                <Form.Item
                                                                                    label="Intake Duration"
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    <Input.Group
                                                                                        compact
                                                                                        className="d-flex"
                                                                                    >
                                                                                        <Form.Item
                                                                                            {...restField}
                                                                                            name={[
                                                                                                name,
                                                                                                "intakeDuration",
                                                                                            ]}
                                                                                            noStyle
                                                                                            rules={[
                                                                                                {
                                                                                                    required: true,
                                                                                                    message:
                                                                                                        "This field is required",
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            <Select
                                                                                                placeholder="Select Duration"
                                                                                                style={{
                                                                                                    width: "50%",
                                                                                                }}
                                                                                            >
                                                                                                {Array(10)
                                                                                                    .fill()
                                                                                                    .map((_, index) => (
                                                                                                        <Select.Option
                                                                                                            key={index}
                                                                                                            value={
                                                                                                                index +
                                                                                                                1
                                                                                                            }
                                                                                                        >
                                                                                                            {index + 1}
                                                                                                        </Select.Option>
                                                                                                    ))}
                                                                                            </Select>
                                                                                        </Form.Item>
                                                                                        <Form.Item
                                                                                            {...restField}
                                                                                            name={[name, "intakeUnit"]}
                                                                                            noStyle
                                                                                            rules={[
                                                                                                {
                                                                                                    required: true,
                                                                                                    message: "",
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            <Select
                                                                                                placeholder="Select Length"
                                                                                                style={{
                                                                                                    width: "50%",
                                                                                                }}
                                                                                            >
                                                                                                <Select.Option value="day">
                                                                                                    Day
                                                                                                </Select.Option>
                                                                                                <Select.Option value="week">
                                                                                                    Week
                                                                                                </Select.Option>
                                                                                                <Select.Option value="month">
                                                                                                    Month
                                                                                                </Select.Option>
                                                                                            </Select>
                                                                                        </Form.Item>
                                                                                    </Input.Group>
                                                                                </Form.Item>
                                                                            </div>
                                                                        </Col>

                                                                        {/* medicineIntakeTime */}
                                                                        <Col span={12}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[name, "medicineIntakeTime"]}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message:
                                                                                            "Missing medicine dose",
                                                                                    },
                                                                                ]}
                                                                                label="Medicine Time"
                                                                            >
                                                                                <Checkbox.Group
                                                                                    className="d-flex flex-column"
                                                                                    // className="d-flex"
                                                                                    options={[
                                                                                        {
                                                                                            label: "Morning",
                                                                                            value: "morning",
                                                                                        },
                                                                                        {
                                                                                            label: "Noon",
                                                                                            value: "noon",
                                                                                        },
                                                                                        {
                                                                                            label: "Evening",
                                                                                            value: "evening",
                                                                                        },
                                                                                        {
                                                                                            label: "Night",
                                                                                            value: "night",
                                                                                        },
                                                                                    ]}
                                                                                />
                                                                            </Form.Item>
                                                                        </Col>

                                                                        {/* Tobe Taken */}
                                                                        <Col span={12}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[name, "toBeTaken"]}
                                                                                size="small"
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message:
                                                                                            "Missing medicine toBeTaken",
                                                                                    },
                                                                                ]}
                                                                                label="To be Taken"
                                                                            >
                                                                                <Radio.Group
                                                                                    options={[
                                                                                        {
                                                                                            label: "Before Food",
                                                                                            value: "before_food",
                                                                                        },
                                                                                        {
                                                                                            label: "After Food",
                                                                                            value: "after_food",
                                                                                        },
                                                                                    ]}
                                                                                    // optionType="button"
                                                                                    buttonStyle="solid"
                                                                                />
                                                                            </Form.Item>
                                                                        </Col>

                                                                        {/* importantNote */}
                                                                        <Col span={24}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[name, "importantNote"]}
                                                                                size="small"
                                                                                label="Important Note"
                                                                            >
                                                                                <TextArea
                                                                                    placeholder="Any important not about this medicine"
                                                                                    autoSize={{
                                                                                        minRows: 2,
                                                                                        maxRows: 6,
                                                                                    }}
                                                                                />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row justify="end">
                                                                        {index !== 0 && (
                                                                            <Col>
                                                                                <Button
                                                                                    onClick={() => remove(name)}
                                                                                    danger
                                                                                >
                                                                                    <DeleteOutlined />
                                                                                </Button>
                                                                            </Col>
                                                                        )}
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    ))}
                                                    <Row justify="end" gutter={[16, 16]}>
                                                        <Col span={3}>
                                                            <Form.Item>
                                                                {/* <Button
                                                                    type="primary"
                                                                    ghost
                                                                    onClick={() => add()}
                                                                    block
                                                                    icon={<PlusOutlined />}
                                                                >
                                                                    Add New
                                                                </Button> */}
                                                                <PlusOutlined
                                                                    className="text-primary add_icon"
                                                                    onClick={() => add()}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>

                                    {/* <Col span={24}>
                                        <Button className="w-100" loading={isUpdatingPrescription} type="primary" htmlType="submit">
                                            Save
                                        </Button>
                                    </Col> */}
                                </Row>
                            </Form>
                        </Drawer>
                    </Fragment>
                );
            }}
        </Observer>
    );
};

export default AddPrescriptionForm;
