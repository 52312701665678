import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";

import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import localStore from "store2";
import useFirstLogin from "utils/hooks/useFirstLogin";
import { isValidArray, setLocalStorageItems } from "utils/utilities";

export const LoginForm = () => {
    const { loginStore } = useMainStore();
    const navigate = useNavigate();
    const isFirtsLogin = useFirstLogin();
    useEffect(() => {
        localStore.clearAll();
    }, []);

    const onFinish = async (values) => {
        try {
            const { handleUserLogin } = loginStore;
            const {
                success,
                data: { userData },
            } = await handleUserLogin(values);

            const [
                { user_id, userType, access_token, refresh_token, isDoctor, isPatient, step = 0, first_login = false },
            ] = userData;

            if (success && isValidArray(userData)) {
                setLocalStorageItems({
                    setAll: true,
                    items: { user_id, userType, access_token, refresh_token, step, first_login },
                });
                if (isDoctor) {
                    if (!first_login) return navigate("/doctor/dashboard");
                    return isFirtsLogin(first_login, step);
                }
                if (isPatient) return navigate("/patient/dashboard");
                navigate("/");
                return;
            }

            throw new Error("Not valid user");
        } catch (error) {
            navigate("/login");
        }
    };

    return (
        <>
            <Observer>
                {() => {
                    const { isUserLogin } = loginStore;
                    return (
                        <>
                            <div className="text-center">
                                <h3>Login</h3>
                            </div>
                            <Form layout="vertical" name="login-form" onFinish={onFinish}>
                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your email",
                                        },
                                        {
                                            type: "email",
                                            message: "Please enter a validate email!",
                                        },
                                    ]}
                                >
                                    <Input prefix={<MailOutlined className="text-primary" />} />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label="Password"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your password",
                                        },
                                    ]}
                                >
                                    <Input.Password prefix={<LockOutlined className="text-primary" />} />
                                </Form.Item>
                                <Form.Item>
                                    <Button loading={isUserLogin} type="primary" htmlType="submit" block>
                                        Login
                                    </Button>
                                </Form.Item>
                            </Form>
                        </>
                    );
                }}
            </Observer>
        </>
    );
};

export default LoginForm;
