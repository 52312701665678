import { Button, Checkbox, Col, Form, Row, Select, Switch, TimePicker } from "antd";
import Loading from "components/shared-components/Loading";
import _ from "lodash";
import { toJS } from "mobx";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { Fragment, useEffect } from "react";
import useFirstLogin from "utils/hooks/useFirstLogin";
import { getLocalStorageItems } from "utils/utilities";

const DoctorAvailability = () => {
    const [form] = Form.useForm();
    const isFirstLogin = useFirstLogin();

    const { doctorProfileStore } = useMainStore();

    useEffect(() => {
        const { getDoctorAvailabilityInfo, setIsLoadingAvailabilityData } = doctorProfileStore;
        const { user_id: doctorId } = getLocalStorageItems({ get: true, key: "user_id" });

        setIsLoadingAvailabilityData(true);
        getDoctorAvailabilityInfo(doctorId).finally(() => setIsLoadingAvailabilityData());
    }, []);

    const onFinish = async (value) => {
        const {
            doctorAvailabilityData,
            addOrUpdateDoctorAvailabilityInfo,
            setIsUpdatingDoctorAvailabilityData,
            setDoctorAvailabilityData,
        } = doctorProfileStore;
        const { availabilityData } = doctorAvailabilityData;
        const utcAvailabilityData = availabilityData.map(({ startTime, endTime, isAvailable, ...info }, ind) => {
            return {
                ...info,
                isAvailable,
                startTime: isAvailable ? moment(value[`${ind}_start_time`]) : "",
                endTime: isAvailable ? moment(value[`${ind}_end_time`]) : "",
            };
        });

        doctorAvailabilityData.availabilityData = utcAvailabilityData;

          const { user_id: doctorId, first_login } = getLocalStorageItems({
              getAll: true,
              key: ["user_id", "step", "first_login"],
          });

        setIsUpdatingDoctorAvailabilityData(true);
        await addOrUpdateDoctorAvailabilityInfo(doctorAvailabilityData, doctorId).finally(() => {
            setIsUpdatingDoctorAvailabilityData();
            isFirstLogin(first_login, 3);
        });
    };

    const handleAvailabilityChange = (e, name, fieldIndex) => {
        const { doctorAvailabilityData, setDoctorAvailabilityData } = toJS(doctorProfileStore);

        const index = _.findIndex(doctorAvailabilityData.availabilityData, { day: name });

        if (index !== -1) {
            doctorAvailabilityData.availabilityData[index].isAvailable = e;

            if (!e) {
                doctorAvailabilityData.availabilityData[index].availability = [];
                doctorAvailabilityData.availabilityData[index].startTime = "";
                doctorAvailabilityData.availabilityData[index].endTime = "";

                form.setFieldsValue({
                    [`${fieldIndex}_availability`]: [],
                    [`${fieldIndex}_start_time`]: "",
                    [`${fieldIndex}_end_time`]: "",
                });
            }
        }

        setDoctorAvailabilityData(doctorAvailabilityData);
    };

    const handleSlotDurationChange = (e) => {
        const { doctorAvailabilityData, setDoctorAvailabilityData } = toJS(doctorProfileStore);
        const slotValue = e.split(" ")[0];
        doctorAvailabilityData.slotDuration = slotValue;
        setDoctorAvailabilityData(doctorAvailabilityData);
    };

    const handleBufferTimeChange = (e) => {
        const { doctorAvailabilityData, setDoctorAvailabilityData } = toJS(doctorProfileStore);
        const bufferValue = e.split(" ")[0];
        doctorAvailabilityData.bufferTime = bufferValue;
        setDoctorAvailabilityData(doctorAvailabilityData);
    };

    const handleOnlineChange = (e, name) => {
        const { doctorAvailabilityData, setDoctorAvailabilityData } = toJS(doctorProfileStore);

        const index = _.findIndex(doctorAvailabilityData.availabilityData, { day: name });

        if (index !== -1) {
            doctorAvailabilityData.availabilityData[index].availability = e;
        }
        setDoctorAvailabilityData(doctorAvailabilityData);
    };

    const handleTimeChange = (e, name, type) => {
        console.log("🚀 ~ handleTimeChange ~ e:", moment(e).toISOString());
        const { doctorAvailabilityData, setDoctorAvailabilityData } = toJS(doctorProfileStore);

        const index = _.findIndex(doctorAvailabilityData.availabilityData, { day: name });

        if (index !== -1 && type === 0) {
            doctorAvailabilityData.availabilityData[index].startTime = moment(e).toISOString();
        }

        if (index !== -1 && type === 1) {
            doctorAvailabilityData.availabilityData[index].endTime = moment(e).toISOString();
        }

        setDoctorAvailabilityData(doctorAvailabilityData);
    };

    return (
        <Observer>
            {() => {
                const { doctorAvailabilityData, isUpdatingDoctorAvailabilityData, isLoadingAvailabilityData } =
                    doctorProfileStore;

                const data = doctorAvailabilityData.availabilityData
                    .map(({ day, isAvailable, availability, startTime, endTime }, index) => {
                        return {
                            [`${index}_isAvailable`]: isAvailable,
                            [`${index}_availability`]: availability,
                            [`${index}_start_time`]: startTime ? moment(startTime, "hh:mm A") : "",
                            [`${index}_end_time`]: endTime ? moment(endTime, "hh:mm A") : "",
                        };
                    })
                    .reduce(
                        (acc, item) => {
                            Object.keys(item).forEach((key) => {
                                acc[key] = item[key];
                            });
                            return acc;
                        },
                        {
                            slotDuration:
                                doctorAvailabilityData.slotDuration !== ""
                                    ? `${doctorAvailabilityData.slotDuration} Minutes`
                                    : "",
                            bufferTime:
                                doctorAvailabilityData.bufferTime !== ""
                                    ? `${doctorAvailabilityData.bufferTime} Minutes`
                                    : "",
                        },
                    );

                if (isLoadingAvailabilityData) {
                    return <Loading />;
                }

                return (
                    <Fragment>
                        <Form
                            form={form}
                            onFinish={onFinish}
                            name="availability_form"
                            autoComplete="off"
                            initialValues={data}
                            // layout="vertical"
                        >
                            <Row gutter={[16, 16]}>
                                <Col span={12} xxl={6} xl={8}>
                                    <Form.Item
                                        name="slotDuration"
                                        label={"Slot Duration"}
                                        rules={[{ required: true, message: "Slot  duration is required." }]}
                                        labelCol={{ span: 24 }}
                                    >
                                        <Select
                                            placeholder="Select Slot Duration"
                                            options={[
                                                { value: "10 Minutes", label: "10 Minutes" },
                                                { value: "15 Minutes", label: "15 Minutes" },
                                                { value: "20 Minutes", label: "20 Minutes" },
                                                { value: "25 Minutes", label: "25 Minutes" },
                                                { value: "30 Minutes", label: "30 Minutes" },
                                            ]}
                                            defaultValue={doctorAvailabilityData.slotDuration}
                                            onChange={handleSlotDurationChange}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12} xxl={6} xl={8}>
                                    <Form.Item
                                        name="bufferTime"
                                        label={"Buffer Time"}
                                        rules={[{ required: true, message: "Buffer time is required." }]}
                                        labelCol={{ span: 24 }}
                                    >
                                        <Select
                                            placeholder="Select break between two slots"
                                            options={[
                                                { value: "02 Minutes", label: "02 Minutes" },
                                                { value: "05 Minutes", label: "05 Minutes" },
                                                { value: "10 Minutes", label: "10 Minutes" },
                                                { value: "15 Minutes", label: "15 Minutes" },
                                                { value: "20 Minutes", label: "20 Minutes" },
                                            ]}
                                            defaultValue={doctorAvailabilityData.bufferTime}
                                            onChange={handleBufferTimeChange}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {doctorAvailabilityData?.availabilityData.map(
                                ({ day, isAvailable, isOnline, startTime, endTime }, index) => {
                                    return (
                                        <Row key={index} align="middle" gutter={[50, 16]}>
                                            <Col span={2}>
                                                <Form.Item>
                                                    <p className="m-0">{day}</p>
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <Form.Item name={`${index}_isAvailable`}>
                                                    <Switch
                                                        defaultChecked={data[`${index}_isAvailable`]}
                                                        onChange={(e) => handleAvailabilityChange(e, day, index)}
                                                    >
                                                        {day}
                                                    </Switch>
                                                </Form.Item>
                                            </Col>
                                            {isAvailable && (
                                                <>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            name={`${index}_availability`}
                                                            label={"Availability"}
                                                            rules={[
                                                                { required: true, message: "This field is required" },
                                                            ]}
                                                        >
                                                            <Checkbox.Group
                                                                onChange={(e) => handleOnlineChange(e, day)}
                                                                options={[
                                                                    { label: "Online", value: "online" },
                                                                    { label: "Offline", value: "offline" },
                                                                ]}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            name={`${index}_start_time`}
                                                            label="From"
                                                            rules={[
                                                                { required: true, message: "Start time is required" },
                                                            ]}
                                                        >
                                                            <TimePicker
                                                                minuteStep={15}
                                                                placeholder="Start Time"
                                                                disabled={!isAvailable}
                                                                allowClear={false}
                                                                size="small"
                                                                className="w-100"
                                                                format={"hh:mm A"}
                                                                onChange={(e) => handleTimeChange(e, day, 0)}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            name={`${index}_end_time`}
                                                            label="To"
                                                            rules={[
                                                                { required: true, message: "End time is required" },
                                                                ({ getFieldValue }) => ({
                                                                    validator(rule, value) {
                                                                        if (
                                                                            value &&
                                                                            moment(value).isAfter(
                                                                                getFieldValue(`${index}_start_time`),
                                                                            ) &&
                                                                            (!moment(value).isSame(
                                                                                getFieldValue(`${index}_start_time`),
                                                                                "hours",
                                                                            ) ||
                                                                                !moment(value).isSame(
                                                                                    getFieldValue(
                                                                                        `${index}_start_time`,
                                                                                    ),
                                                                                    "minute",
                                                                                ))
                                                                        ) {
                                                                            return Promise.resolve();
                                                                        } else if (value) {
                                                                            return Promise.reject(
                                                                                "Please don't select past time or same time",
                                                                            );
                                                                        } else {
                                                                            return Promise.resolve();
                                                                        }
                                                                    },
                                                                }),
                                                            ]}
                                                        >
                                                            <TimePicker
                                                                minuteStep={15}
                                                                placeholder="End Time"
                                                                disabled={!isAvailable}
                                                                allowClear={false}
                                                                size="small"
                                                                className="w-100"
                                                                format={"hh:mm A"}
                                                                onChange={(e) => handleTimeChange(e, day, 1)}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                            )}
                                        </Row>
                                    );
                                },
                            )}

                            <Form.Item>
                                <Button loading={isUpdatingDoctorAvailabilityData} type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </Form.Item>
                        </Form>
                    </Fragment>
                );
            }}
        </Observer>
    );
};

export default DoctorAvailability;
