import { DOCTOR_APPOINTMENTS_BY_DATE, DOCTOR_DASHBOARD_GRAPH_DATA } from "constants/ApiConstant";
import { makeAutoObservable, runInAction } from "mobx";
import { getRequest } from "utils/api";
import { getValidDataFromResponse, isValidArray } from "utils/utilities";

class DoctorDashboardStore {
    constructor() {
        makeAutoObservable(this);
    }

    initialDashboardStatistics = [
        {
            appointmentsCount: [],
            totalPatientsServed: [],
            totalRevenue: [],
        },
    ];
    initialDashboardData = [
        {
            appointmentsCount: [
                {
                    month: "Jan",
                    count: 0,
                },
                {
                    month: "Feb",
                    count: 0,
                },
                {
                    month: "Mar",
                    count: 0,
                },
                {
                    month: "Apr",
                    count: 0,
                },
                {
                    month: "May",
                    count: 0,
                },
                {
                    month: "Jun",
                    count: 0,
                },
                {
                    month: "Jul",
                    count: 0,
                },
                {
                    month: "Aug",
                    count: 0,
                },
                {
                    month: "Sep",
                    count: 0,
                },
                {
                    month: "Oct",
                    count: 0,
                },
                {
                    month: "Nov",
                    count: 0,
                },
                {
                    month: "Dec",
                    count: 0,
                },
            ],
            totalPatientsServed: [
                {
                    month: "Jan",
                    patientsCount: 0,
                },
                {
                    month: "Feb",
                    patientsCount: 0,
                },
                {
                    month: "Mar",
                    patientsCount: 0,
                },
                {
                    month: "Apr",
                    patientsCount: 0,
                },
                {
                    month: "May",
                    patientsCount: 0,
                },
                {
                    month: "Jun",
                    patientsCount: 0,
                },
                {
                    month: "Jul",
                    patientsCount: 0,
                },
                {
                    month: "Aug",
                    patientsCount: 0,
                },
                {
                    month: "Sep",
                    patientsCount: 0,
                },
                {
                    month: "Oct",
                    patientsCount: 0,
                },
                {
                    month: "Nov",
                    patientsCount: 0,
                },
                {
                    month: "Dec",
                    patientsCount: 0,
                },
            ],
            totalRevenue: [
                {
                    month: "Jan",
                    total: 0,
                },
                {
                    month: "Feb",
                    total: 0,
                },
                {
                    month: "Mar",
                    total: 0,
                },
                {
                    month: "Apr",
                    total: 0,
                },
                {
                    month: "May",
                    total: 0,
                },
                {
                    month: "Jun",
                    total: 0,
                },
                {
                    month: "Jul",
                    total: 0,
                },
                {
                    month: "Aug",
                    total: 0,
                },
                {
                    month: "Sep",
                    total: 0,
                },
                {
                    month: "Oct",
                    total: 0,
                },
                {
                    month: "Nov",
                    total: 0,
                },
                {
                    month: "Dec",
                    total: 0,
                },
            ],
        },
    ];
    dashboardData = this.initialDashboardStatistics;
    dashboardAppointmentList = [];

    isLoadingDashboardData = true;

    getDashboardStatistics = async (doctorId, year) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { error, response } = await getRequest({
                    url: `${DOCTOR_DASHBOARD_GRAPH_DATA}/${doctorId}/${year}`,
                });

                const { error: responseError, data } = getValidDataFromResponse({ response });

                if (!responseError && isValidArray(data)) {
                    const [{ appointmentsCount, totalPatientsServed, totalRevenue }] = data;
                    if (appointmentsCount.length == 0 && totalRevenue.length == 0) {
                        this.setDashboardData(this.initialDashboardData);
                    } else {
                        this.setDashboardData(data);
                    }
                    return resolve({ success: true, data });
                }

                this.setDashboardData();
                return resolve({ success: false, data: [] });
            } catch (error) {
                this.setDashboardData();
                return reject({ success: false, data: [], message: error.message });
            }
        });
    };

    getDashboardAppointmentList = async ({ doctorId, limit }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { error, response } = await getRequest({
                    url: `${DOCTOR_APPOINTMENTS_BY_DATE}/${doctorId}?limit=${limit}`,
                });

                const { error: responseError, data } = getValidDataFromResponse({ response });

                if (!responseError && isValidArray(data)) {
                    this.setDashboardAppointmentList(data);
                    return resolve({ success: true, data });
                }

                this.setDashboardAppointmentList();
                return resolve({ success: false, data: [] });
            } catch (error) {
                this.setDashboardAppointmentList();
                return reject({ success: false, data: [], message: error.message });
            }
        });
    };

    setDashboardData = (data = this.initialDashboardStatistics) => {
        runInAction(() => {
            this.dashboardData = data;
        });
    };

    setIsLoadingDashboardData = (value = false) => {
        runInAction(() => {
            this.isLoadingDashboardData = value;
        });
    };

    setDashboardAppointmentList = (data = []) => {
        runInAction(() => {
            this.dashboardAppointmentList = data;
        });
    };
}

export default DoctorDashboardStore;
