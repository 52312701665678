import { GET_PATIENT_PRESCRIPTION_DETAILS, GET_PATIENT_PRESCRIPTION_LIST } from "constants/ApiConstant";
import { makeAutoObservable, runInAction } from "mobx";
import { getRequest } from "utils/api";
import { getValidDataFromResponse, isValidArray } from "utils/utilities";

class PatientPrescriptionStore {
    patientPrescriptionList = [];
    isLoadingPatientPrescriptionList = true;

    patientPrescriptionDetails = [];
    isLoadingPatientPrescriptionDetails = true;

    constructor() {
        makeAutoObservable(this);
    }

    getPatientPrescriptionList = async (patientId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await getRequest({ url: `${GET_PATIENT_PRESCRIPTION_LIST}/${patientId}` });
                const { data, error } = getValidDataFromResponse({ response });

                if (!error && isValidArray(data)) {
                    this.setPatientPrescriptionList(data);
                    return resolve({ success: true, data });
                }
                this.setPatientPrescriptionList();

                return resolve({ success: true, data: [] });
            } catch (error) {
                console.log("error", error);
                this.setPatientPrescriptionList();
                return resolve({ success: false, data: [] });
            }
        });
    };
    getPatientPrescriptionDetails = async (patientId, prescriptionId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await getRequest({
                    url: `${GET_PATIENT_PRESCRIPTION_DETAILS}/${patientId}/${prescriptionId}`,
                });
                const { data, error } = getValidDataFromResponse({ response });

                if (!error && isValidArray(data)) {
                    this.setPatientPrescriptionDetails(data);
                    return resolve({ success: true, data });
                }
                this.setPatientPrescriptionDetails();

                return resolve({ success: true, data: [] });
            } catch (error) {
                console.log("error", error);
                this.setPatientPrescriptionDetails();
                return resolve({ success: false, data: [] });
            }
        });
    };

    setPatientPrescriptionList = (data = []) => {
        runInAction(() => {
            this.patientPrescriptionList = data;
        });
    };
    setIsLoadingPatientPrescriptionList = (value = false) => {
        runInAction(() => {
            this.isLoadingPatientPrescriptionList = value;
        });
    };
    setPatientPrescriptionDetails = (data = []) => {
        runInAction(() => {
            this.patientPrescriptionDetails = data;
        });
    };
    setIsLoadingPatientPrescriptionDetails = (value = false) => {
        runInAction(() => {
            this.isLoadingPatientPrescriptionDetails = value;
        });
    };
}

export default PatientPrescriptionStore;
