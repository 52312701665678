import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useMainStore } from "mobxStore/mainStore";
import { useNavigate } from "react-router-dom";

const { confirm } = Modal;
export const useAppointmentComplitionModal = () => {
    const { doctorStore, videoConferenceStore, doctorViewAppointmentStore } = useMainStore();
    const navigate = useNavigate();
    const showConfirm = async (appointmentId, patientId, doctorId, type) => {
        return new Promise((resolve, reject) => {
            const { updateAppointmentStatusByDoctor } = doctorStore;
            const { setIsUpdatingCompletionStatus, updateAppointmentCompletionStatus } = videoConferenceStore;
            const { getSingleAppointmentDataForDoctor } = doctorViewAppointmentStore;

            if (type == 0) {
                confirm({
                    title: "Are you sure you want to complete this appointment?",
                    icon: <ExclamationCircleOutlined />,
                    content: "This action cannot be undone",
                    async onOk() {
                        try {
                            return updateAppointmentStatusByDoctor(appointmentId).then(async ({ success, data }) => {
                                if (success) {
                                    const body = {
                                        completionStatus: "completed",
                                        appointmentId,
                                        patientId,
                                        doctorId,
                                        updatedBy: doctorId,
                                    };
                                    const { success, data } = await updateAppointmentCompletionStatus(body);
                                    if (success) {
                                        navigate(`/doctor/view-appointment`, {
                                            state: {
                                                appointmentId: appointmentId,
                                                patientId: patientId,
                                                openModal: false,
                                            },
                                            replace: true,
                                        });
                                        resolve({ success: true });
                                    }
                                    await getSingleAppointmentDataForDoctor({
                                        doctorId,
                                        appointmentId: appointmentId,
                                    });
                                }
                                resolve({ success: false });
                            });
                        } catch (error) {
                            resolve({ success: false });
                        }
                    },
                    onCancel() {
                        console.log("Cancel");
                         navigate(`/doctor/view-appointment`, {
                             state: {
                                 appointmentId: appointmentId,
                                 patientId: patientId,
                                 openModal: false,
                             },
                             replace: true,
                         });
                        resolve({ success: false });
                    },
                });
            }
            if (type == 1) {
                confirm({
                    title: "Are you sure you want to leave this session?",
                    icon: <ExclamationCircleOutlined />,
                    content: "",
                    okText: "Yes",
                    cancelText: "No",
                    async onOk() {
                        try {
                            return resolve({ success: true });
                        } catch (error) {
                            resolve({ success: false });
                        }
                    },
                    onCancel() {
                        console.log("Cancel");
                        resolve({ success: false });
                    },
                });
            }
        });
    };

    
    const confirmationModal = async () => {
        return new Promise((resolve, reject) => {
            confirm({
                title: "Are you sure you want to end this session?",
                icon: <ExclamationCircleOutlined />,
                content: "",
                async onOk() {
                    try {
                        resolve({ success: true });
                    } catch (error) {
                        resolve({ success: false });
                    }
                },
                onCancel() {
                    console.log("Cancel");
                    resolve({ success: false });
                },
            });
        });
    };

    return { showConfirm, confirmationModal };
};
